import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import history from "./history";

import App from "./App";
import { GuestListPage } from "./admin/guestListPage";
import { EventsPage } from "./admin/events";
import { RsvpsPage } from "./admin/rsvps";
import { SeatingPage } from "./admin/seating";
import { DashboardPage } from "./admin/dashboard";
import { DirectionsPage } from "./info/directions";
import { DressCodePage } from "./info/dresscode";
import { ContactsPage } from "./info/contacts";
import { FaqPage } from "./info/faq";
import { NewsPage } from "./info/news";
import { MannersPage } from "./info/manners";
import { ImagesPage } from "./info/images";
import { AccommodationsPage } from "./info/accommodations";
import { MenuPage } from "./info/menu";
import { AboutPage } from "./info/about";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/info/directions"
          render={props => <DirectionsPage {...props} />}
        />
        <Route
          path="/info/dresscode"
          render={props => <DressCodePage {...props} />}
        />
        <Route
          path="/info/contacts"
          render={props => <ContactsPage {...props} />}
        />
        <Route path="/info/faq" render={props => <FaqPage {...props} />} />
        <Route
          path="/info/housing"
          render={props => <AccommodationsPage {...props} />}
        />
        <Route
          path="/info/accommodations"
          render={props => <AccommodationsPage {...props} />}
        />
        <Route
          path="/info/images"
          render={props => <ImagesPage {...props} />}
        />
        <Route
          path="/info/manners"
          render={props => <MannersPage {...props} />}
        />
        <Route
          path="/info/about"
          render={props => <AboutPage {...props} />}
        />
        <Route path="/info/menu" render={props => <MenuPage {...props} />} />
        <Route path="/info/news" render={props => <NewsPage {...props} />} />

        <Route
          path="/admin/guestlist"
          render={props => <GuestListPage {...props} />}
        />
        <Route
          path="/admin/seating"
          render={props => <SeatingPage {...props} />}
        />
        <Route
          path="/admin/events"
          render={props => <EventsPage {...props} />}
        />
        <Route path="/admin/rsvps" render={props => <RsvpsPage {...props} />} />
        <Route path="/admin/dashboard" render={props => <DashboardPage {...props} />} />
        <Route path="/" render={props => <App {...props} />} />
      </Switch>
    </Router>
  );
};

export default Routes;
