import React, { EventHandler, SyntheticEvent, useCallback, useState } from "react";
import { useAuth0 } from "../lib/auth0";
import history from "../history";
import { useTranslation } from "react-i18next";
import svImg from "../assets/img/sv.svg";
import enImg from "../assets/img/en.svg";
import { slide as Menu } from "react-burger-menu";



const LoginButton = (props: any) => {
  const { logout, loginWithPopup, isAuthenticated } = useAuth0();
  const { t } = useTranslation();

  const onLoginClick: EventHandler<SyntheticEvent<
    HTMLAnchorElement
  >> = async e => {
    e.preventDefault();
    await loginWithPopup();
  };

  const onLogoutClick: EventHandler<SyntheticEvent<
    HTMLAnchorElement
  >> = async e => {
    e.preventDefault();
    logout();
  };

  return isAuthenticated ? (
    <a {...props} onClick={onLogoutClick}>
      {t("common:header.menuLogou")}
    </a>
  ) : (
      <a {...props} onClick={onLoginClick}>
        {t("common:header.menuLogin")}
      </a>
    );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const useGoToHandler = (history: any) => {

    return useCallback(route => () => {
      window.scrollTo(0, 0);
      setIsOpen(false);
      history.replace(`/${route}`)
    }
      , [history]);
  };
  const goToHandler = useGoToHandler(history);
  const { t, i18n } = useTranslation();
  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  const { hasPermissions } = useAuth0();


  const CustomBurgerIcon = () => (
    <button type="button" className="open-btn">
      <span className="sr-only">{t("common:header.toggleNavigation")}</span>
      <span className="icon-bar"></span>
      <span className="icon-bar"></span>
      <span className="icon-bar"></span>
    </button>
  );

  const AdminMenus = () =>
    hasPermissions("is:admin") ? (
      <React.Fragment>
        <h4>{t("common:header.menuAdmin")}</h4>
        <div className="menu-item-has-children">
          <a className="bm-item" onClick={goToHandler("admin/guestlist")}>
            {t("common:header.menuAdminGuestList")}
          </a>
          <br />
          <a className="bm-item" onClick={goToHandler("admin/rsvps")}>
            {t("common:header.menuAdminRsvps")}
          </a>
          <br />
          <a className="bm-item" onClick={goToHandler("admin/dashboard")}>
            Dashboard
          </a>
          <br />
          <a className="bm-item" onClick={goToHandler("admin/seating")}>
            {t("common:header.menuAdminSeating")}
          </a>
        </div>
      </React.Fragment>
    ) : null;

  const HomeMenus = () => (
    <React.Fragment>
      <h4>{t("common:header.menuHome")}</h4>
      <div className="menu-item-has-children">
        <a className="bm-item" onClick={goToHandler("#rsvp")}>
          {t("common:header.menuRsvp")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("#couple")}>
          {t("common:header.menuCouple")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("#story")}>
          {t("common:header.menuStory")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("#events")}>
          {t("common:header.menuEvents")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("#people")}>
          {t("common:header.menuPeople")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("#gallery")}>
          {t("common:header.menuGallery")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("#gifts")}>
          {t("common:header.menuGifts")}
        </a>
      </div>
    </React.Fragment>
  );

  const InfoMenus = () => (
    <React.Fragment>
      <h4>{t("common:header.menuInfo")}</h4>
      <div className="menu-item-has-children">
        <a className="bm-item" onClick={goToHandler("info/news")}>
          {t("common:header.menuInfoNews")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/faq")}>
          {t("common:header.menuInfoFaq")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/directions")}>
          {t("common:header.menuInfoDirections")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/accommodations")}>
          {t("common:header.menuInfoHousing")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/dresscode")}>
          {t("common:header.menuInfoDresscode")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/contacts")}>
          {t("common:header.menuInfoContacts")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/menu")}>
          {t("common:header.menuInfoMenu")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("admin/events")}>
          {t("common:header.menuAdminEvents")}
        </a>
        <br />
        <a className="bm-item" onClick={goToHandler("info/about")}>
          {t("common:header.menuAboutMenu")}
        </a>
        {/* <br />
        <a className="bm-item" onClick={goToHandler("info/images")}>
          {t("common:header.menuInfoImages")}
        </a>
        <br /> */}
      </div>
    </React.Fragment>
  );

  const ActionMenus = () => (
    <React.Fragment>
      <h4>{t("common:header.menuActions")}</h4>
      <div className="menu-item-has-children">
        <LanguageFlags className={"bm-item"} />
        <br />
      </div>
    </React.Fragment>
  );

  const LanguageFlags = (props: any) => (
    <React.Fragment>
      <span {...props}>
        <a onClick={() => setLanguage("sv")}>
          <img src={svImg} alt="Svenska" />
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a onClick={() => setLanguage("en")}>
          <img src={enImg} alt="English" />
        </a>
      </span>
    </React.Fragment>
  );

  return (
    <header id="header" className="site-header header-style-1">
      <nav className="navigation navbar navbar-default">
        <div className="container">
          <div className="navbar-header">
            <a className="navbar-brand font-fam-clic" href="index.html">
              André <i className="fi ti-heart"></i> Hannah
            </a>
            <Menu
              id="slide"
              isOpen={isOpen}
              right={true}
              onStateChange={(state) => setIsOpen(state.isOpen)}
              customBurgerIcon={<CustomBurgerIcon />}
            >
              <HomeMenus />
              <InfoMenus />
              <ActionMenus />
              <AdminMenus />
              <LoginButton className={"bm-item"} />
            </Menu>
          </div>
          <div
            id="navbar"
            className="navbar-collapse collapse navbar-right navigation-holder"
          >
            <button className="close-navbar">
              <i className="ti-close"></i>
            </button>

            <ul className="nav navbar-nav">
              <li>
                <a href="#" onClick={() => setLanguage("sv")}>
                  <img src={svImg} alt="Svenska" />
                </a>
              </li>
              <li>
                <a href="#" onClick={() => setLanguage("en")}>
                  <img src={enImg} alt="English" />
                </a>
              </li>

              <li className="menu-item-has-children">
                <a href="#">{t("common:header.menuHome")}</a>
                <ul className="sub-menu">
                  <li>
                    <a onClick={goToHandler("")}>
                      {t("common:header.menuHome")}
                    </a>
                  </li>
                  <li>
                    <a onClick={goToHandler("#couple")}>
                      {t("common:header.menuCouple")}
                    </a>
                  </li>
                  <li>
                    <a onClick={goToHandler("#story")}>
                      {t("common:header.menuStory")}
                    </a>
                  </li>
                  <li>
                    <a onClick={goToHandler("#events")}>
                      {t("common:header.menuEvents")}
                    </a>
                  </li>
                  <li>
                    <a onClick={goToHandler("#people")}>
                      {t("common:header.menuPeople")}
                    </a>
                  </li>
                  <li>
                    <a onClick={goToHandler("#gallery")}>
                      {t("common:header.menuGallery")}
                    </a>
                  </li>
                  <li>
                    <a onClick={goToHandler("#gifts")}>
                      {t("common:header.menuGifts")}
                    </a>
                  </li>
                </ul>
              </li>


              <li className="menu-item-has-children">
                <a href="#">{t("common:header.menuInfo")}</a>
                <ul className="sub-menu">
                  <li>
                    <a className="bm-item" onClick={goToHandler("info/news")}>
                      {t("common:header.menuInfoNews")}
                    </a>
                  </li>
                  <li>
                    <a className="bm-item" onClick={goToHandler("info/faq")}>
                      {t("common:header.menuInfoFaq")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="bm-item"
                      onClick={goToHandler("info/directions")}
                    >
                      {t("common:header.menuInfoDirections")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="bm-item"
                      onClick={goToHandler("info/accommodations")}
                    >
                      {t("common:header.menuInfoHousing")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="bm-item"
                      onClick={goToHandler("info/dresscode")}
                    >
                      {t("common:header.menuInfoDresscode")}
                    </a>
                  </li>
                  <li>
                    <a className="bm-item"
                      onClick={goToHandler("info/contacts")}>
                      {t("common:header.menuInfoContacts")}
                    </a>
                  </li>
                  <li>
                    <a className="bm-item" onClick={goToHandler("info/menu")}>
                      {t("common:header.menuInfoMenu")}
                    </a>
                  </li>
                  <li>
                    <a className="bm-item" onClick={goToHandler("admin/events")}>
                      {t("common:header.menuAdminEvents")}
                    </a>
                  </li>
                  <li>
                    <a className="bm-item" onClick={goToHandler("info/about")}>
                      {t("common:header.menuAboutMenu")}
                    </a>
                  </li>
                  {/* <li>
                    <a className="bm-item" onClick={goToHandler("info/images")}>
                      {t("common:header.menuInfoImages")}
                    </a>
                  </li> */}
                </ul>
              </li>

              <li>
                <a onClick={goToHandler("info/news")}>
                  {t("common:header.menuInfoNews")}
                </a>
              </li>

              <li>
                <a onClick={goToHandler("admin/events")}>                  
                  {t("common:header.menuAdminEvents")}
                </a>
              </li>

              {hasPermissions("is:admin") && (
                <li className="menu-item-has-children">
                  <a href="#">{t("common:header.menuAdmin")}</a>
                  <ul className="sub-menu">
                    <li>
                      <a onClick={goToHandler("admin/guestlist")}>
                        {t("common:header.menuAdminGuestList")}
                      </a>
                    </li>

                    <li>
                      <a onClick={goToHandler("admin/rsvps")}>
                        {t("common:header.menuAdminRsvps")}
                      </a>
                    </li>
                    <li>
                      <a onClick={goToHandler("admin/dashboard")}>
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a onClick={goToHandler("admin/seating")}>
                        {t("common:header.menuAdminSeating")}
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              <li>
                <LoginButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
