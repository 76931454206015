import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";
import { usePersonByNameLazyQuery } from "../../graphql/types";
import Loading from "../../components/loading";
import RsvpHeader from "./rsvpHeader";

const RsvpEnterName = () => {
  const { dispatch } = useRsvpContext();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tried, setTried] = useState(false);

  const [findPersonByName, { loading, data }] = usePersonByNameLazyQuery();

  if (loading) return <Loading />;

  if (data && data.Persons && data.Persons.length === 1) {
    const id = data && data.Persons.length > 0 ? data?.Persons[0].id : 0;
    console.log('found person', id)
    dispatch({
      respondingPerson: id,
      currentStep: RsvpStep.Choose
    });
  }

  return (
    <>
    <RsvpHeader/>
    <div className="rsvp-form">
      <form>
        <div className="form-group">
          <label htmlFor="inputFirstname">{t('common:firstname')}</label>
          <input type="text" className="form-control" id="inputFirstname" defaultValue={firstName} onChange={e => setFirstName(e.target.value)} placeholder={t('common:rsvpGetNameFirstName')}/>
        </div>
        <div className="form-group">
          <label htmlFor="inputLastname">{t('common:lastname')}</label>
          <input type="text" className="form-control" id="inputLastname" defaultValue={lastName} onChange={e => setLastName(e.target.value)} placeholder={t('common:rsvpGetNameLastName')} />
        </div>
        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{
        __html: t('common:rsvpGetName')
      }} >
      </div>
        <div className="submit-btn">
          {tried &&
            <div className="alert alert-warning" role="alert"><span className="glyphicon glyphicon-pencil" />&nbsp;&nbsp;{t('common:rsvpGetNameCouldNotFind')}</div>
          }
          <button className="theme-btn-s2" onClick={() => {

            findPersonByName({
              variables: {
                firstname: firstName,
                lastname: lastName
              }
            });
            setTried(true);
          }}>
            {t('common:buttonNext')}
          </button>
        </div>
      </form>
    </div>
    </>
  );
};

export default RsvpEnterName;