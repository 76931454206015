import React from "react";
import "moment/locale/sv";
import { useTranslation } from "react-i18next";

const RsvpHeader = () => {
  const { t } = useTranslation();
  return <>
    <h3>{t("common:rsvpSection.formTitle")}</h3>
    <br />
    <p>{t("common:rsvpSection.rsvpBefore")}</p>
  </>
};

export default RsvpHeader;
