import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Person, Event, EventTypeEnum, NewResponse, usePostRsvpMutation } from "../../graphql/types";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";
import moment from "moment";
import "moment/locale/sv";
import RsvpHeader from "./rsvpHeader";

const RsvpAccommodations = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useRsvpContext();
  const [responses, setResponses] = useState<NewResponse[]>(state.responses!);
  const [addRsvps] = usePostRsvpMutation();

  if (!state.rsvpForm) {
    return <div>Could not retrieve rsvp form</div>;
  }

  const party = state.rsvpForm.Persons[0].parties[0].party;
  const people = party.people.map(pp => pp.person) as Person[];
  const events = people
    .map(p => p.invitations.map(i => i.event))
    .reduce((a: Array<Event>, b: Array<Event>) => a.concat(b));

  const result: Event[] = [];
  const map = new Map();
  for (const item of events) {
    if (!map.has(item.name)) {
      map.set(item.name, true);    // set any value to Map
      result.push(item);
    }
  }

  const sortedDistinctEvents = result
    .filter(e => e.event_type === EventTypeEnum.BookableEvent)
    .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());


  const MatrixHeaders = () => {
    const { t } = useTranslation();
    return <thead className="text-left">
      <tr>
        <th scope="col"></th>
        {sortedDistinctEvents.map((h, i) =>
          <th key={i} className="rotated-text" scope="col"><div><span>{t(h.name) + moment(h.start_time).format(' (D MMM)')}</span></div></th>
        )}
      </tr>
    </thead>;
  }

  const setResponseValue = (invitationId: number, attending: boolean) => {
    if (!responses) {
      return;
    }

    setResponses(prevState => {
      const r = prevState.find(r => r.invitationId === invitationId);
      if (r) {
        r.attending = attending;
      }
      return prevState;
    });
  }

  const MatrixRows = () => <>
    {people.map((p, idx) => <tr key={idx}>
      <th scope="row">{p.firstname}</th>
      {p.invitations.filter(i => i.event.event_type === EventTypeEnum.BookableEvent)
        .sort((a, b) => new Date(a.event.start_time).getTime() - new Date(b.event.start_time).getTime())
        .map(i =>
          <td key={i.id}><input type="checkbox" onChange={e => setResponseValue(i.id, e.target.checked)} /></td>
        )}
    </tr>)}
  </>


  const Matrix = () => {
    return <table className="table table-hover" style={{ width: '90%' }}>
      <MatrixHeaders />
      <tbody>
        <MatrixRows />
      </tbody>
    </table>;
  }

  const PostResponses = () => {
    // post responses
    addRsvps({
      variables: {
        input: responses
      }
    }).then(r => {
      dispatch({
        responses: responses,
        // get generated ids, store as responses in context
        savedResponses: r.data?.CreateResponses,
        currentStep: RsvpStep.AllergiesAndExtras
      });
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <>
      <RsvpHeader />
      <br />
      <h4>{t('common:rsvpAccomodationsTitle')}</h4>
      <br />
      <Matrix />
      <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{
        __html: t('common:rsvpSeeAccommodationsInfo')
      }} >
      </div>
      <br />
      <div className="submit-btn">
        <span>
          <button className="theme-btn-s2" onClick={() => dispatch({
            currentStep: RsvpStep.Events
          })}>{t('common:buttonPrevious')}</button>
          <button className="theme-btn-s2" onClick={() => PostResponses()}>{t('common:buttonNext')}</button>
        </span>
      </div>
    </>
  );
};

export default RsvpAccommodations;
