import React, {useCallback} from "react";
import history from "../../history";
import "moment/locale/sv";
import { Trans } from "react-i18next";

const useGoToHandler = (history: any) => {
  return useCallback(route => () => history.replace(`/${route}`), [history]);
};

const RsvpThankYou = () => {
  //const { t } = useTranslation();
  const goToHandler = useGoToHandler(history);

 
  return (
    <Trans i18nKey='common:rsvpThankYou'>
      <br />
      <h3>Tack för ert svar!</h3>
      <br/>
      <div style={{textAlign: 'left'}} >
      <p>
        Vänligen använd menyn för att navigera till alla undersidor som innehåller detaljinformation kring bröllopets olika delar.
      </p>
      <br/>
      <p>
      <strong>Observera!</strong> Eftersom COVID-19/Corona härjar just nu så kan vi tvingas att ändra datum på bröllopet. Vi ber er därför att regelbundet återkomma till denna 
      sida och läsa våra nyheter där vi kommer att uppdatera kring situationen.
      </p>
      <br/>
      <h4>Detaljsidor</h4>
      <ul>    
        <li><a onClick={goToHandler("info/faq")}>Vanliga frågor och svar</a></li>
        <li><a onClick={goToHandler("info/news")}>Nyheter</a></li>
        <li><a onClick={goToHandler("info/housing")}>Läs mer om boende</a></li>
      </ul>      

      </div>
      <br/>      
      <h4>Vi ses snart!</h4>
    </Trans>
  );
};

export default RsvpThankYou;
