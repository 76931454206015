import React from "react";
import ReactWOW from 'react-wow';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

const HeaderSwiper = () => {
    const params = {
        loop: true,
        speed: 1000,
        parallax: false,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        }
    }

    return (
        <Swiper {...params}>
            <div style={{ 
                backgroundImage: "url(assets/images/slider/slide-1.jpg)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }} /> 

            <div style={{ 
                backgroundImage: "url(assets/images/slider/slide-2.jpg)",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }} />              
        </Swiper>
    )
}


const Hero = () => {


    return (<section className="hero-slider hero-style-1">
        <div className="wedding-announcement">
            <div className="couple-text">

                <ReactWOW animation='slideInUp' duration='1s'><h2 className="couple-name font-fam-clic">André <i className="fi ti-heart"></i> Hannah</h2></ReactWOW>
            </div>
        </div>

        <HeaderSwiper />
    </section>
    );
}

export default Hero;
