import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useRsvpFormQuery, Person, Event, EventTypeEnum, NewResponse } from "../../graphql/types";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";
import Loading from "../../components/loading";
import moment from "moment";
import "moment/locale/sv";
import RsvpHeader from "./rsvpHeader";

const RsvpEvents = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useRsvpContext();
  const [responses, setResponses] = useState<NewResponse[]>([]);

  const { data, loading, error } = useRsvpFormQuery({
    variables: {
      id: state.respondingPerson
    }
  });


  // Prepare responses
  useEffect(() => {
    const party = data?.Persons[0].parties[0].party;
    const people = party?.people.map(pp => pp.person) as Person[];

    if (people) {
      const responsesTmp: NewResponse[] = [];
      people.forEach(p => p.invitations.forEach(i => {
        responsesTmp.push(
          {
            attending: false,
            invalidated: false,
            invitationId: i.id,
            responseTime: new Date(),
            responseUser: state.respondingPerson.toString()
          });
      }));

      console.log('setting responses', responsesTmp);
      setResponses(responsesTmp);
    }

  }, [data, state.respondingPerson]);

  // Store rsvpform in context
  if (loading) {
    return <Loading />;
  }

  const party = data?.Persons[0].parties[0].party;
  const people = party?.people.map(pp => pp.person) as Person[];
  const events = people ? people
    .map(p => p.invitations.map(i => i.event))
    .reduce((a: Array<Event>, b: Array<Event>) => a.concat(b))
    : undefined;

  if (error || !data || !party || !events) {
    return <div>Could not retrieve rsvp form</div>;
  }

  // const getResponseValue = (invitationId: number): boolean => {
  //   const r = responses.find(r => r.invitationId === invitationId);
  //   return r ? r.attending : false;
  // }

  const setResponseValue = (invitationId: number, attending: boolean) => {
    if (!responses) {
      return;
    }

    setResponses(prevState => {
      const r = prevState.find(r => r.invitationId === invitationId);
      if (r) {
        r.attending = attending;
      } 
      console.log('updating responses', prevState);
      return prevState;
    });
  }

  const result: Event[] = [];
  const map = new Map();
  for (const item of events) {
    if (!map.has(item.name)) {
      map.set(item.name, true);    // set any value to Map
      result.push(item);
    }
  }

  const sortedDistinctEvents = result
    .filter(e => e.event_type === EventTypeEnum.MainEvent)
    .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());


  const MatrixHeaders = () => {
    const { t } = useTranslation();
    return <thead className="text-left">
      <tr>
        <th scope="col"></th>
        {sortedDistinctEvents.map((h, i) =>
          <th key={i} className="rotated-text" scope="col"><div><span>{t(h.name) + moment(h.start_time).format(' (D MMM)')}</span></div></th>
        )}
      </tr>
    </thead>;
  }

  const MatrixRows = () => <>
    {people.map((p, idx) => <tr key={idx}>
      <th scope="row">{p.firstname}</th>
      {p.invitations
        // sort and filter the same way as the headers
        .filter(i => i.event.event_type === EventTypeEnum.MainEvent)
        .sort((a, b) => new Date(a.event.start_time).getTime() - new Date(b.event.start_time).getTime())
        .map(i =>
        <td key={i.id}><input type="checkbox" onChange={e => setResponseValue(i.id, e.target.checked)} /></td>
      )}
    </tr>)}
  </>


  const Matrix = () => {
    return <table className="table table-hover" style={{ width: '90%' }}>
      <MatrixHeaders />
      <tbody>
        <MatrixRows />
      </tbody>
    </table>;
  }

  const partyName = party.name;

  return (
    <>
      <RsvpHeader />
      <br />
      <h4><Trans i18nKey={'common:rsvpPartyParticipation'} >Deltagande för {{ partyName }}</Trans></h4>
      <br />
      <Matrix />
      <div style={{ textAlign: 'left' }} >
        <ul>
          <li><Trans i18nKey={'common:rsvpPartyAnswerForAll'}>Svara för <strong>hela</strong> ditt sällskap</Trans></li>
          <li><Trans i18nKey={'common:rsvpPartyForeach'}>För <strong>varje</strong> deltagare</Trans>
            <ul>
              <li><Trans i18nKey={'common:rsvpPartyMarkAllEvents'}>Markera <strong>samtliga</strong> tillställningar som deltagaren kommer att närvara vid</Trans></li>
            </ul>
          </li>
        </ul>
      </div>
      <br />
      <div className="submit-btn">
        <span>
          <button className="theme-btn-s2" onClick={() => dispatch({
            respondingPerson: 0,
            currentStep: RsvpStep.PickLogin
          })}>{t('common:buttonPrevious')}</button>
          <button className="theme-btn-s2" onClick={() => dispatch({
            rsvpForm: data,
            responses: responses,
            currentStep: RsvpStep.Accommodations
          })}>{t('common:buttonNext')}</button>
        </span>

      </div>
    </>
  );
};

export default RsvpEvents;
