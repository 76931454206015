import React from "react";
import "moment/locale/sv";
import { Trans } from "react-i18next";

const RsvpSorry = () => {
 
  return (
    <Trans i18nKey='common:rsvpSorry'>
      <br />
      <h3>Tack för ert svar!</h3>
      <br/>
      <div style={{textAlign: 'left'}} >
      <p>
        Vad tråkigt att du inte kan komma. Hoppas vi ses snart ändå.
      </p>        
      </div>
      <br/>      
      <h4>Kramar från André och Hannah!</h4>
    </Trans>
  );
};

export default RsvpSorry;
