import React from "react";
import { useTranslation } from "react-i18next";

const GiftRegistrySection = () => {
    const { t } = useTranslation();

    return (
        <section className="gift-registry-section section-padding-2" id="gifts">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-lg-offset-3">
                        <div className="section-title-s2">
                            <h2>{t('common:header.menuGifts')}</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-8 col-lg-offset-2">
                        <div className="gift-title">
                            <p dangerouslySetInnerHTML={{
                                __html: t('common:giftRegistrySection.description')
                            }} ></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default GiftRegistrySection;
