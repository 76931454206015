import React from "react";
import { useTranslation } from "react-i18next";

const GallerySection = () => {
    const {t} = useTranslation();
    return (
    <section className="gallery-section section-padding" id="gallery">
        <div className="container">
            <div className="row">
                <div className="col col-xs-12">
                    <div className="section-title">
                        <h2>{t('common:header.menuGallery')}</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col col-xs-12">
                    <div className="gallery-grids">
                        <div className="grid">
                            <a href="/assets/images/gallery/img-1.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-1.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="/assets/images/gallery/img-2.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-2.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="/assets/images/gallery/img-3.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-3.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="/assets/images/gallery/img-4.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-4.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="/assets/images/gallery/img-5.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-5.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="/assets/images/gallery/img-6.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-6.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="/assets/images/gallery/img-7.jpg" className="fancybox" data-fancybox-group="gall-1">
                                <img src="/assets/images/gallery/img-7.jpg" alt="" />
                            </a>
                        </div>
                        <div className="grid">
                            <a href="https://www.youtube.com/embed/7GWSl1GQSSc" data-type="iframe" className="video-play-btn">
                                <img src="/assets/images/gallery/img-8.jpg" className="img img-responsive" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default GallerySection;
