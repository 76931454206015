import React  from "react";
import { EventResponseOption, OptionKindEnum, ResponseOption, Invitation, NewResponseOptionValue } from "../../../graphql/types";
import { useTranslation } from "react-i18next";
import { useRsvpContext } from "../../../rsvpContext";

type EventResponseOptionProps = {
    eventResponseOption: EventResponseOption,
    invitation: Invitation,
    onChange?: (newItem: NewResponseOptionValue) => void
}

type OptionInputprops = {
    option: ResponseOption,
    invitation: Invitation,
    onChange?: (newItem: NewResponseOptionValue) => void
}

const OptionInput = ({ option, invitation, onChange }: OptionInputprops) => {
    // Context
    const { t } = useTranslation();
    const { state } = useRsvpContext();

    const placeholderKey = option.values && option.values.length > 0 ? option.values[0] : undefined;
    const placeholderValue = placeholderKey ? t(placeholderKey) : undefined;
    const key = 'erovi-' + option.id + '-' + invitation.id;

    const onInputChange =  (value:any) => {
        if (onChange){
            const response = state.savedResponses?.find(r => r.invitation!.id === invitation.id)!;
            onChange({
                responseId: response.id!,
                responseOptionId: option.id,
                values: [value]
            });
        }
    }

    return option.kind === OptionKindEnum.Checkbox
        ? <input key={key} name={key} type="checkbox" onChange={(e) => onInputChange(e.target.checked.toString())} />
        : <input key={key} name={key} type="input" size={12} placeholder={placeholderValue} onChange={(e) => onInputChange(e.target.value)} />
}

const EventResponseOptionComponent = ({ eventResponseOption, invitation, onChange }: EventResponseOptionProps) => {
    const { t } = useTranslation();

    return <tr>
        <td>
            <p>
                {t(eventResponseOption.response_option.label)}
            </p>
        </td>
        <td>
            <OptionInput
                key={'optioninput-' + eventResponseOption.id + '-' + invitation.id}
                option={eventResponseOption.response_option}
                invitation={invitation}
                onChange={onChange}
            />
        </td>
    </tr>;
}

export default EventResponseOptionComponent