import React from "react";
import { useTranslation } from "react-i18next";

const WishesSection = () => {
    const {t} = useTranslation();
    return (
    <section className="wishes-section">
        <div className="container">
            <div className="row">
                <div className="col col-lg-6 col-lg-offset-3">
                    <div className="text">
                        <h2>&nbsp;</h2>
                        <p>&nbsp;</p>
                        <a href="#rsvp" className="theme-btn">{t('common:rsvpSection.formButtonRsvp')}</a>
                        <a className="theme-btn" href="https://g.page/HotelOresundConferenceSpa?share">
                            {t('common:wishesSection.title')} <i className="fa fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
);
}
export default WishesSection;
