import React from "react";
import { useTranslation } from "react-i18next";

const SaveTheDateSection = () => {
    const { t } = useTranslation();

    const CurveText = () => (
        <svg viewBox="0 0 500 500" id="curve-text">
            <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
            <text>
                <textPath href="#curve" startOffset="50%" textAnchor="middle">
                    {t('common:saveTheDateSection.title')}
                </textPath>
            </text>
        </svg>
      );

return (
    <section className="save-the-date-section">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <div className="save-the-date popup-save-the-date">
                        <CurveText />
                        <div className="waves-block">
                            <div className="waves wave-1"></div>
                            <div className="waves wave-2"></div>
                            <div className="waves wave-3"></div>
                        </div>
                        <h4>{t('common:saveTheDate')}</h4>
                        <span className="date">{t('common:theDate')}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
);
}
export default SaveTheDateSection;
