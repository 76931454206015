import React from 'react';
import {
    Event, 
    useEventsQuery, EventColumns, Order
} from '../graphql/types';
import Loading from '../components/loading';
import moment from "moment";
import "moment/locale/sv";
import { useTranslation } from 'react-i18next';

interface EventItemProps {
    event: Partial<Event>
}

let previousDayString = '';

const EventItem = (props:EventItemProps) => {
    const { t } = useTranslation();
    const { event } = props;
    
    const start = moment(event.start_time);
    const end = moment(event.end_time);

    const startDayString = start.format("dddd D MMM");
    const dayString = startDayString === previousDayString
        ? ''
        : startDayString;
    previousDayString = startDayString;
    
    const startTimeOfDay = start.format("HH:mm");
    const endTimeOfDay = end.format("HH:mm");

    const timeString = startTimeOfDay === endTimeOfDay
        ? startTimeOfDay
        : startTimeOfDay + '-' + endTimeOfDay;

    return  <div className="entry">
    <div className="title">
        <h3>{dayString}</h3>
        <span>{timeString}</span>
    </div>
    <div className="body">
        <h4>{t(event.name!)}</h4>
        {t(event.description!)}
    </div>
</div>
}

export const EventsPage = (props: any) => {
    const { t } = useTranslation();
    const { data, loading, error } = useEventsQuery({
        variables: {
            order: {
                column: EventColumns.StartTime,
                direction: Order.Desc
            }
        }
    });
    const events = data?.Events;

    if (loading || !events) {
        return <Loading />;
    }

    if (error) {
        console.log('error', error);
        return <div>Could not retrieve events</div>;
    }

    const EventsList = events.map(e => <EventItem key={e.id} event={e} />);

    return <section className="gift-registry-section section-padding-2">
        <div className="container">
            <div className="row">
                <div className="col col-lg-6 col-lg-offset-3">
                    <div className="section-title-s2">
                        <h2>{t('common:eventsPageTitle')}</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="timeline">
                    {EventsList}
                </div>
            </div>
        </div>
    </section>
};
