import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { reactPlugin } from "./AppInsights";

const AppInsightsContext = createContext(reactPlugin);
const useAppInsightsContext = () => useContext(AppInsightsContext);

const AppInsightsContextProvider = ({ children }) => {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};


export default function useCustomEvent(
    reactPlugin,
    eventName,
    eventData,
    skipFirstRun = true
) {
    const [data, setData] = useState(eventData);
    const firstRun = useRef(skipFirstRun);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return;
        }
        reactPlugin.trackEvent({ name: eventName }, data);
    }, [reactPlugin, data, eventName]);

    return setData;
}



export { AppInsightsContext, AppInsightsContextProvider, useAppInsightsContext, useCustomEvent };

