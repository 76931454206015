import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
    return (
    <footer className="site-footer footer-style-1 bg-1">
        <div className="inner">
            <div className="couple-pic">
                <img src="/assets/images/add/footer-couple-pic.jpg" alt="André & Hannah"/>
            </div>
            <h2 style={{color: 'white'}}>{t('common:footer.thankYou')}</h2>
            <h6>{t('common:theDate')}</h6>
            <div dangerouslySetInnerHTML={{__html: t('common:footer.copyright', {interpolation: {escapeValue: false}})}} />
        </div>
    </footer>
);
}
export default Footer;
