
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './CheckBrowser';

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom';
import history from './history'
import { AUTH_CONFIG } from './lib/auth0-variables'
import { Auth0Provider } from './lib/auth0'

import Routes from './routes';
import Header from './components/header';
import Footer from './components/footer';

import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Loading from './components/loading';
import { ApolloClient, DefaultOptions } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { AppInsightsContextProvider } from './AppInsightsContext';
import { RsvpContextProvider } from './rsvpContext';


// Auth
const onAuthRedirectCallback = (redirectResult?: RedirectLoginResult) => {
  console.log(
    'auth0 onRedirectCallback called with redirectState %o',
    redirectResult
  )

  // Clears auth0 query string parameters from url
  const targetUrl = redirectResult
    && redirectResult.appState
    && redirectResult.appState.targetUrl
    ? redirectResult.appState.targetUrl
    : window.location.pathname

  history.push(targetUrl)
}


// Graphql
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const baseUrl = process.env.REACT_APP_API_ROOT + '/graphql';

const httpLink = createHttpLink({
  uri: baseUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});

ReactDOM.render(
  (
    <AppInsightsContextProvider>
      <Auth0Provider
        domain={AUTH_CONFIG.domain}
        client_id={AUTH_CONFIG.clientID}
        redirect_uri={window.location.origin}
        audience={AUTH_CONFIG.audience}
        onRedirectCallback={onAuthRedirectCallback}
      >
        <Suspense fallback={<Loading />}>
          <RsvpContextProvider>
            <ApolloProvider client={client}>
              <div className="page-wrapper">
                <Header />
                <br />
                <br />
                <Routes />
                <Footer />
              </div>
            </ApolloProvider>
          </RsvpContextProvider>
        </Suspense>
      </Auth0Provider>
    </AppInsightsContextProvider>
  ),
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
