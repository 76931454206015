import React from "react";
import { Person, NewResponseOptionValue } from "../../../graphql/types";
import InvitationRows from './invitationOptionValueRows';

type PersonRowProps = {
    person: Person,
    onChange?: (newItem: NewResponseOptionValue) => void
}

type PersonListProps = {
    persons: Person[],
    onChange?: (newItem: NewResponseOptionValue) => void
}

const PersonRow = ({ person, onChange }: PersonRowProps) => 
    <div>
        <strong>
            {person.firstname}
        </strong>
        <table className="table table-hover" style={{ width: '90%' }}>
            <tbody>
                <InvitationRows invitations={person.invitations} onChange={onChange} />
            </tbody>
        </table>
    </div>;


const PersonList = ({ persons, onChange }: PersonListProps) => {
    const list = persons.map((p) => <PersonRow key={'person-' + p.id} person={p} onChange={onChange} />);
    return (<>{list}</>);
}   

export default PersonList