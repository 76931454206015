import React from 'react';
import GuestList from './guestList';

export const GuestListPage = (props: any) => {
  return <section className="gift-registry-section section-padding-2">
  <div className="container">
      <div className="row">
          <div className="col col-lg-6 col-lg-offset-3">
              <div className="section-title-s2">
                  <h2>Guest list</h2>
              </div>
          </div>
      </div>
      
      <div className="row">
            <GuestList/>
      </div>
  </div>
</section>
};
