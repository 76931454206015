import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTimeUtc: any;
  BigInt: any;
};

export type Address = {
   __typename: 'Address';
  id: Scalars['Int'];
  address_line: Scalars['String'];
  postal_code: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type AddressChangeset = {
  id: Scalars['Int'];
  addressLine?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export enum AddressColumns {
  Id = 'id',
  AddressLine = 'address_line',
  PostalCode = 'postal_code',
  City = 'city',
  Country = 'country'
}

export type AddressesPrimaryKey = {
  id: Scalars['Int'];
};

export type AddressFilter = {
  and?: Maybe<Array<AddressFilter>>;
  or?: Maybe<Array<AddressFilter>>;
  not?: Maybe<AddressFilter>;
  id?: Maybe<Filter_Int_>;
  address_line?: Maybe<Filter_String_>;
  postal_code?: Maybe<Filter_String_>;
  city?: Maybe<Filter_String_>;
  country?: Maybe<Filter_String_>;
};

export type AddressOrderBy = {
  column: AddressColumns;
  direction?: Maybe<Order>;
};



export type DeletedCount = {
   __typename: 'DeletedCount';
  count: Scalars['BigInt'];
};

export type Event = {
   __typename: 'Event';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  location_url?: Maybe<Scalars['String']>;
  base_price?: Maybe<Scalars['Int']>;
  unit_price?: Maybe<Scalars['Int']>;
  start_time: Scalars['DateTimeUtc'];
  end_time?: Maybe<Scalars['DateTimeUtc']>;
  event_type: EventTypeEnum;
  address_id?: Maybe<Scalars['Int']>;
  address: Address;
  options: Array<EventResponseOption>;
};


export type EventOptionsArgs = {
  filter?: Maybe<EventResponseOptionFilter>;
};

export type EventChangeset = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locationUrl?: Maybe<Scalars['String']>;
  basePrice?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTimeUtc']>;
  endTime?: Maybe<Scalars['DateTimeUtc']>;
  eventType?: Maybe<EventTypeEnum>;
  addressId?: Maybe<Scalars['Int']>;
};

export enum EventColumns {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  ShortDescription = 'short_description',
  ImageUrl = 'image_url',
  LocationUrl = 'location_url',
  BasePrice = 'base_price',
  UnitPrice = 'unit_price',
  StartTime = 'start_time',
  EndTime = 'end_time',
  EventType = 'event_type',
  AddressId = 'address_id',
  Address = 'address'
}

export type EventFilter = {
  and?: Maybe<Array<EventFilter>>;
  or?: Maybe<Array<EventFilter>>;
  not?: Maybe<EventFilter>;
  id?: Maybe<Filter_Int_>;
  name?: Maybe<Filter_String_>;
  description?: Maybe<Filter_Nullable_String__>;
  short_description?: Maybe<Filter_Nullable_String__>;
  image_url?: Maybe<Filter_Nullable_String__>;
  location_url?: Maybe<Filter_Nullable_String__>;
  base_price?: Maybe<Filter_Nullable_Int__>;
  unit_price?: Maybe<Filter_Nullable_Int__>;
  start_time?: Maybe<Filter_DateTime_>;
  end_time?: Maybe<Filter_Nullable_DateTime__>;
  event_type?: Maybe<Filter_EventTypeEnum_>;
  address_id?: Maybe<Filter_Nullable_Int__>;
  address?: Maybe<AddressFilter>;
  options?: Maybe<EventResponseOptionFilter>;
};

export type EventOrderBy = {
  column: EventColumns;
  direction?: Maybe<Order>;
};

export type EventResponseOption = {
   __typename: 'EventResponseOption';
  id: Scalars['Int'];
  event: Event;
  response_option: ResponseOption;
};

export type EventResponseOptionChangeset = {
  id: Scalars['Int'];
  eventId?: Maybe<Scalars['Int']>;
  responseOptionId?: Maybe<Scalars['Int']>;
};

export type EventResponseOptionFilter = {
  and?: Maybe<Array<EventResponseOptionFilter>>;
  or?: Maybe<Array<EventResponseOptionFilter>>;
  not?: Maybe<EventResponseOptionFilter>;
  id?: Maybe<Filter_Int_>;
  event?: Maybe<EventFilter>;
  response_option?: Maybe<ResponseOptionFilter>;
};

export type EventsPrimaryKey = {
  id: Scalars['Int'];
};

export enum EventTypeEnum {
  MainEvent = 'MAIN_EVENT',
  BookableEvent = 'BOOKABLE_EVENT',
  DetailEvent = 'DETAIL_EVENT'
}

export type Faq_ItemsPrimaryKey = {
  id: Scalars['Int'];
};

export type FaqItem = {
   __typename: 'FaqItem';
  id: Scalars['Int'];
  question_sv: Scalars['String'];
  question_en: Scalars['String'];
  answer_sv: Scalars['String'];
  answer_en: Scalars['String'];
  publish_timestamp: Scalars['DateTimeUtc'];
  author: Person;
};

export type FaqItemChangeset = {
  id: Scalars['Int'];
  questionSv?: Maybe<Scalars['String']>;
  questionEn?: Maybe<Scalars['String']>;
  answerSv?: Maybe<Scalars['String']>;
  answerEn?: Maybe<Scalars['String']>;
  publishTimestamp?: Maybe<Scalars['DateTimeUtc']>;
  authorId?: Maybe<Scalars['Int']>;
};

export enum FaqItemColumns {
  Id = 'id',
  QuestionSv = 'question_sv',
  QuestionEn = 'question_en',
  AnswerSv = 'answer_sv',
  AnswerEn = 'answer_en',
  PublishTimestamp = 'publish_timestamp',
  Author = 'author'
}

export type FaqItemFilter = {
  and?: Maybe<Array<FaqItemFilter>>;
  or?: Maybe<Array<FaqItemFilter>>;
  not?: Maybe<FaqItemFilter>;
  id?: Maybe<Filter_Int_>;
  question_sv?: Maybe<Filter_String_>;
  question_en?: Maybe<Filter_String_>;
  answer_sv?: Maybe<Filter_String_>;
  answer_en?: Maybe<Filter_String_>;
  publish_timestamp?: Maybe<Filter_DateTime_>;
  author?: Maybe<PersonFilter>;
};

export type FaqItemOrderBy = {
  column: FaqItemColumns;
  direction?: Maybe<Order>;
};

export type Filter_Bool_ = {
  eq?: Maybe<Scalars['Boolean']>;
  not_eq?: Maybe<Scalars['Boolean']>;
  eq_any?: Maybe<Array<Scalars['Boolean']>>;
};

export type Filter_DateTime_ = {
  eq?: Maybe<Scalars['DateTimeUtc']>;
  not_eq?: Maybe<Scalars['DateTimeUtc']>;
  eq_any?: Maybe<Array<Scalars['DateTimeUtc']>>;
};

export type Filter_EventTypeEnum_ = {
  eq?: Maybe<EventTypeEnum>;
  not_eq?: Maybe<EventTypeEnum>;
  eq_any?: Maybe<Array<EventTypeEnum>>;
};

export type Filter_GenderEnum_ = {
  eq?: Maybe<GenderEnum>;
  not_eq?: Maybe<GenderEnum>;
  eq_any?: Maybe<Array<GenderEnum>>;
};

export type Filter_Int_ = {
  eq?: Maybe<Scalars['Int']>;
  not_eq?: Maybe<Scalars['Int']>;
  eq_any?: Maybe<Array<Scalars['Int']>>;
};

export type Filter_Nullable_DateTime__ = {
  eq?: Maybe<Scalars['DateTimeUtc']>;
  not_eq?: Maybe<Scalars['DateTimeUtc']>;
  eq_any?: Maybe<Array<Maybe<Scalars['DateTimeUtc']>>>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Filter_Nullable_Int__ = {
  eq?: Maybe<Scalars['Int']>;
  not_eq?: Maybe<Scalars['Int']>;
  eq_any?: Maybe<Array<Maybe<Scalars['Int']>>>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Filter_Nullable_String__ = {
  eq?: Maybe<Scalars['String']>;
  not_eq?: Maybe<Scalars['String']>;
  eq_any?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_null?: Maybe<Scalars['Boolean']>;
  like?: Maybe<Scalars['String']>;
};

export type Filter_OptionKindEnum_ = {
  eq?: Maybe<OptionKindEnum>;
  not_eq?: Maybe<OptionKindEnum>;
  eq_any?: Maybe<Array<OptionKindEnum>>;
};

export type Filter_SideEnum_ = {
  eq?: Maybe<SideEnum>;
  not_eq?: Maybe<SideEnum>;
  eq_any?: Maybe<Array<SideEnum>>;
};

export type Filter_String_ = {
  eq?: Maybe<Scalars['String']>;
  not_eq?: Maybe<Scalars['String']>;
  eq_any?: Maybe<Array<Scalars['String']>>;
  like?: Maybe<Scalars['String']>;
};

export type Filter_Vec_String__ = {
  eq?: Maybe<Array<Scalars['String']>>;
  not_eq?: Maybe<Array<Scalars['String']>>;
  eq_any?: Maybe<Array<Array<Scalars['String']>>>;
};

export enum GenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  Unknown = 'UNKNOWN'
}

export type Invitation = {
   __typename: 'Invitation';
  id: Scalars['Int'];
  rsvp: Scalars['DateTimeUtc'];
  person: Person;
  event: Event;
};

export type InvitationChangeset = {
  id: Scalars['Int'];
  rsvp?: Maybe<Scalars['DateTimeUtc']>;
  personId?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
};

export enum InvitationColumns {
  Id = 'id',
  Rsvp = 'rsvp',
  Person = 'person',
  Event = 'event'
}

export type InvitationFilter = {
  and?: Maybe<Array<InvitationFilter>>;
  or?: Maybe<Array<InvitationFilter>>;
  not?: Maybe<InvitationFilter>;
  id?: Maybe<Filter_Int_>;
  rsvp?: Maybe<Filter_DateTime_>;
  person?: Maybe<PersonFilter>;
  event?: Maybe<EventFilter>;
};

export type InvitationOrderBy = {
  column: InvitationColumns;
  direction?: Maybe<Order>;
};

export type InvitationsPrimaryKey = {
  id: Scalars['Int'];
};

export type Mutation = {
   __typename: 'Mutation';
  CreateNewsItem?: Maybe<NewsItem>;
  CreateNewsItems: Array<NewsItem>;
  CreateFaqItem?: Maybe<FaqItem>;
  CreateFaqItems: Array<FaqItem>;
  CreateAddress?: Maybe<Address>;
  CreateAddresss: Array<Address>;
  CreateParty?: Maybe<Party>;
  CreatePartys: Array<Party>;
  CreatePerson?: Maybe<Person>;
  CreatePersons: Array<Person>;
  CreatePartyPerson?: Maybe<PartyPerson>;
  CreatePartyPersons: Array<PartyPerson>;
  CreateEvent?: Maybe<Event>;
  CreateEvents: Array<Event>;
  CreateEventResponseOption?: Maybe<EventResponseOption>;
  CreateEventResponseOptions: Array<EventResponseOption>;
  CreateInvitation?: Maybe<Invitation>;
  CreateInvitations: Array<Invitation>;
  CreateResponse?: Maybe<Response>;
  CreateResponses: Array<Response>;
  CreateResponseOption?: Maybe<ResponseOption>;
  CreateResponseOptions: Array<ResponseOption>;
  CreateResponseOptionValue?: Maybe<ResponseOptionValue>;
  CreateResponseOptionValues: Array<ResponseOptionValue>;
  CreateTable?: Maybe<Table>;
  CreateTables: Array<Table>;
  CreateTablePerson?: Maybe<TablePerson>;
  CreateTablePersons: Array<TablePerson>;
  UpdateNewsItem?: Maybe<NewsItem>;
  UpdateFaqItem?: Maybe<FaqItem>;
  UpdateAddress?: Maybe<Address>;
  UpdateParty?: Maybe<Party>;
  UpdatePerson?: Maybe<Person>;
  UpdatePartyPerson?: Maybe<PartyPerson>;
  UpdateEvent?: Maybe<Event>;
  UpdateEventResponseOption?: Maybe<EventResponseOption>;
  UpdateInvitation?: Maybe<Invitation>;
  UpdateResponse?: Maybe<Response>;
  UpdateResponseOption?: Maybe<ResponseOption>;
  UpdateResponseOptionValue?: Maybe<ResponseOptionValue>;
  UpdateTable?: Maybe<Table>;
  UpdateTablePerson?: Maybe<TablePerson>;
  DeleteNewsItem?: Maybe<DeletedCount>;
  DeleteFaqItem?: Maybe<DeletedCount>;
  DeleteAddress?: Maybe<DeletedCount>;
  DeleteParty?: Maybe<DeletedCount>;
  DeletePerson?: Maybe<DeletedCount>;
  DeletePartyPerson?: Maybe<DeletedCount>;
  DeleteEvent?: Maybe<DeletedCount>;
  DeleteEventResponseOption?: Maybe<DeletedCount>;
  DeleteInvitation?: Maybe<DeletedCount>;
  DeleteResponse?: Maybe<DeletedCount>;
  DeleteResponseOption?: Maybe<DeletedCount>;
  DeleteResponseOptionValue?: Maybe<DeletedCount>;
  DeleteTable?: Maybe<DeletedCount>;
  DeleteTablePerson?: Maybe<DeletedCount>;
};


export type MutationCreateNewsItemArgs = {
  NewNewsItem: NewNewsItem;
};


export type MutationCreateNewsItemsArgs = {
  NewNewsItems: Array<NewNewsItem>;
};


export type MutationCreateFaqItemArgs = {
  NewFaqItem: NewFaqItem;
};


export type MutationCreateFaqItemsArgs = {
  NewFaqItems: Array<NewFaqItem>;
};


export type MutationCreateAddressArgs = {
  NewAddress: NewAddress;
};


export type MutationCreateAddresssArgs = {
  NewAddresss: Array<NewAddress>;
};


export type MutationCreatePartyArgs = {
  NewParty: NewParty;
};


export type MutationCreatePartysArgs = {
  NewPartys: Array<NewParty>;
};


export type MutationCreatePersonArgs = {
  NewPerson: NewPerson;
};


export type MutationCreatePersonsArgs = {
  NewPersons: Array<NewPerson>;
};


export type MutationCreatePartyPersonArgs = {
  NewPartyPerson: NewPartyPerson;
};


export type MutationCreatePartyPersonsArgs = {
  NewPartyPersons: Array<NewPartyPerson>;
};


export type MutationCreateEventArgs = {
  NewEvent: NewEvent;
};


export type MutationCreateEventsArgs = {
  NewEvents: Array<NewEvent>;
};


export type MutationCreateEventResponseOptionArgs = {
  NewEventResponseOption: NewEventResponseOption;
};


export type MutationCreateEventResponseOptionsArgs = {
  NewEventResponseOptions: Array<NewEventResponseOption>;
};


export type MutationCreateInvitationArgs = {
  NewInvitation: NewInvitation;
};


export type MutationCreateInvitationsArgs = {
  NewInvitations: Array<NewInvitation>;
};


export type MutationCreateResponseArgs = {
  NewResponse: NewResponse;
};


export type MutationCreateResponsesArgs = {
  NewResponses: Array<NewResponse>;
};


export type MutationCreateResponseOptionArgs = {
  NewResponseOption: NewResponseOption;
};


export type MutationCreateResponseOptionsArgs = {
  NewResponseOptions: Array<NewResponseOption>;
};


export type MutationCreateResponseOptionValueArgs = {
  NewResponseOptionValue: NewResponseOptionValue;
};


export type MutationCreateResponseOptionValuesArgs = {
  NewResponseOptionValues: Array<NewResponseOptionValue>;
};


export type MutationCreateTableArgs = {
  NewTable: NewTable;
};


export type MutationCreateTablesArgs = {
  NewTables: Array<NewTable>;
};


export type MutationCreateTablePersonArgs = {
  NewTablePerson: NewTablePerson;
};


export type MutationCreateTablePersonsArgs = {
  NewTablePersons: Array<NewTablePerson>;
};


export type MutationUpdateNewsItemArgs = {
  UpdateNewsItem: NewsItemChangeset;
};


export type MutationUpdateFaqItemArgs = {
  UpdateFaqItem: FaqItemChangeset;
};


export type MutationUpdateAddressArgs = {
  UpdateAddress: AddressChangeset;
};


export type MutationUpdatePartyArgs = {
  UpdateParty: PartyChangeset;
};


export type MutationUpdatePersonArgs = {
  UpdatePerson: PersonChangeset;
};


export type MutationUpdatePartyPersonArgs = {
  UpdatePartyPerson: PartyPersonChangeset;
};


export type MutationUpdateEventArgs = {
  UpdateEvent: EventChangeset;
};


export type MutationUpdateEventResponseOptionArgs = {
  UpdateEventResponseOption: EventResponseOptionChangeset;
};


export type MutationUpdateInvitationArgs = {
  UpdateInvitation: InvitationChangeset;
};


export type MutationUpdateResponseArgs = {
  UpdateResponse: ResponseChangeset;
};


export type MutationUpdateResponseOptionArgs = {
  UpdateResponseOption: ResponseOptionChangeset;
};


export type MutationUpdateResponseOptionValueArgs = {
  UpdateResponseOptionValue: ResponseOptionValueChangeset;
};


export type MutationUpdateTableArgs = {
  UpdateTable: TableChangeset;
};


export type MutationUpdateTablePersonArgs = {
  UpdateTablePerson: TablePersonChangeset;
};


export type MutationDeleteNewsItemArgs = {
  DeleteNewsItem: News_ItemsPrimaryKey;
};


export type MutationDeleteFaqItemArgs = {
  DeleteFaqItem: Faq_ItemsPrimaryKey;
};


export type MutationDeleteAddressArgs = {
  DeleteAddress: AddressesPrimaryKey;
};


export type MutationDeletePartyArgs = {
  DeleteParty: PartiesPrimaryKey;
};


export type MutationDeletePersonArgs = {
  DeletePerson: PersonsPrimaryKey;
};


export type MutationDeletePartyPersonArgs = {
  DeletePartyPerson: Rel_Persons_PartiesPrimaryKey;
};


export type MutationDeleteEventArgs = {
  DeleteEvent: EventsPrimaryKey;
};


export type MutationDeleteEventResponseOptionArgs = {
  DeleteEventResponseOption: Rel_Events_Response_OptionsPrimaryKey;
};


export type MutationDeleteInvitationArgs = {
  DeleteInvitation: InvitationsPrimaryKey;
};


export type MutationDeleteResponseArgs = {
  DeleteResponse: ResponsesPrimaryKey;
};


export type MutationDeleteResponseOptionArgs = {
  DeleteResponseOption: Response_OptionsPrimaryKey;
};


export type MutationDeleteResponseOptionValueArgs = {
  DeleteResponseOptionValue: Response_Option_ValuePrimaryKey;
};


export type MutationDeleteTableArgs = {
  DeleteTable: TablesPrimaryKey;
};


export type MutationDeleteTablePersonArgs = {
  DeleteTablePerson: Rel_Tables_PersonsPrimaryKey;
};

export type NewAddress = {
  addressLine: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type NewEvent = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locationUrl?: Maybe<Scalars['String']>;
  basePrice?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTimeUtc'];
  endTime?: Maybe<Scalars['DateTimeUtc']>;
  eventType: EventTypeEnum;
  addressId?: Maybe<Scalars['Int']>;
};

export type NewEventResponseOption = {
  eventId: Scalars['Int'];
  responseOptionId: Scalars['Int'];
};

export type NewFaqItem = {
  questionSv: Scalars['String'];
  questionEn: Scalars['String'];
  answerSv: Scalars['String'];
  answerEn: Scalars['String'];
  publishTimestamp: Scalars['DateTimeUtc'];
  authorId: Scalars['Int'];
};

export type NewInvitation = {
  rsvp: Scalars['DateTimeUtc'];
  personId: Scalars['Int'];
  eventId: Scalars['Int'];
};

export type NewNewsItem = {
  titleSv: Scalars['String'];
  titleEn: Scalars['String'];
  contentSv: Scalars['String'];
  contentEn: Scalars['String'];
  publishTimestamp: Scalars['DateTimeUtc'];
  publishStart: Scalars['DateTimeUtc'];
  publishEnd?: Maybe<Scalars['DateTimeUtc']>;
  authorId: Scalars['Int'];
};

export type NewParty = {
  name: Scalars['String'];
};

export type NewPartyPerson = {
  personId: Scalars['Int'];
  partyId: Scalars['Int'];
};

export type NewPerson = {
  firstname: Scalars['String'];
  lastname?: Maybe<Scalars['String']>;
  gender: GenderEnum;
  side: SideEnum;
  yearOfBirth?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type NewResponse = {
  responseTime: Scalars['DateTimeUtc'];
  attending: Scalars['Boolean'];
  invalidated: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  responseUser: Scalars['String'];
  invitationId: Scalars['Int'];
};

export type NewResponseOption = {
  label: Scalars['String'];
  values: Array<Scalars['String']>;
  kind: OptionKindEnum;
};

export type NewResponseOptionValue = {
  values: Array<Scalars['String']>;
  responseId: Scalars['Int'];
  responseOptionId: Scalars['Int'];
};

export type News_ItemsPrimaryKey = {
  id: Scalars['Int'];
};

export type NewsItem = {
   __typename: 'NewsItem';
  id: Scalars['Int'];
  title_sv: Scalars['String'];
  title_en: Scalars['String'];
  content_sv: Scalars['String'];
  content_en: Scalars['String'];
  publish_timestamp: Scalars['DateTimeUtc'];
  publish_start: Scalars['DateTimeUtc'];
  publish_end?: Maybe<Scalars['DateTimeUtc']>;
  author: Person;
};

export type NewsItemChangeset = {
  id: Scalars['Int'];
  titleSv?: Maybe<Scalars['String']>;
  titleEn?: Maybe<Scalars['String']>;
  contentSv?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  publishStart?: Maybe<Scalars['DateTimeUtc']>;
  publishEnd?: Maybe<Scalars['DateTimeUtc']>;
  authorId: Scalars['Int'];
};

export enum NewsItemColumns {
  Id = 'id',
  TitleSv = 'title_sv',
  TitleEn = 'title_en',
  ContentSv = 'content_sv',
  ContentEn = 'content_en',
  PublishTimestamp = 'publish_timestamp',
  PublishStart = 'publish_start',
  PublishEnd = 'publish_end',
  Author = 'author'
}

export type NewsItemFilter = {
  and?: Maybe<Array<NewsItemFilter>>;
  or?: Maybe<Array<NewsItemFilter>>;
  not?: Maybe<NewsItemFilter>;
  id?: Maybe<Filter_Int_>;
  title_sv?: Maybe<Filter_String_>;
  title_en?: Maybe<Filter_String_>;
  content_sv?: Maybe<Filter_String_>;
  content_en?: Maybe<Filter_String_>;
  publish_timestamp?: Maybe<Filter_DateTime_>;
  publish_start?: Maybe<Filter_DateTime_>;
  publish_end?: Maybe<Filter_Nullable_DateTime__>;
  author?: Maybe<PersonFilter>;
};

export type NewsItemOrderBy = {
  column: NewsItemColumns;
  direction?: Maybe<Order>;
};

export type NewTable = {
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  eventId: Scalars['Int'];
};

export type NewTablePerson = {
  personId: Scalars['Int'];
  tableId: Scalars['Int'];
};

export enum OptionKindEnum {
  Checkbox = 'CHECKBOX',
  Option = 'OPTION',
  String = 'STRING',
  Number = 'NUMBER'
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PartiesPrimaryKey = {
  id: Scalars['Int'];
};

export type Party = {
   __typename: 'Party';
  id: Scalars['Int'];
  name: Scalars['String'];
  people: Array<PartyPerson>;
};


export type PartyPeopleArgs = {
  filter?: Maybe<PartyPersonFilter>;
};

export type PartyChangeset = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export enum PartyColumns {
  Id = 'id',
  Name = 'name'
}

export type PartyFilter = {
  and?: Maybe<Array<PartyFilter>>;
  or?: Maybe<Array<PartyFilter>>;
  not?: Maybe<PartyFilter>;
  id?: Maybe<Filter_Int_>;
  name?: Maybe<Filter_String_>;
  people?: Maybe<PartyPersonFilter>;
};

export type PartyOrderBy = {
  column: PartyColumns;
  direction?: Maybe<Order>;
};

export type PartyPerson = {
   __typename: 'PartyPerson';
  id: Scalars['Int'];
  person: Person;
  party: Party;
};

export type PartyPersonChangeset = {
  id: Scalars['Int'];
  personId?: Maybe<Scalars['Int']>;
  partyId?: Maybe<Scalars['Int']>;
};

export type PartyPersonFilter = {
  and?: Maybe<Array<PartyPersonFilter>>;
  or?: Maybe<Array<PartyPersonFilter>>;
  not?: Maybe<PartyPersonFilter>;
  id?: Maybe<Filter_Int_>;
  person?: Maybe<PersonFilter>;
  party?: Maybe<PartyFilter>;
};

export type Person = {
   __typename: 'Person';
  id: Scalars['Int'];
  firstname: Scalars['String'];
  lastname?: Maybe<Scalars['String']>;
  gender: GenderEnum;
  side: SideEnum;
  year_of_birth?: Maybe<Scalars['Int']>;
  address_id?: Maybe<Scalars['Int']>;
  address: Address;
  parties: Array<PartyPerson>;
  invitations: Array<Invitation>;
  user_id?: Maybe<Scalars['String']>;
  profile_image_url?: Maybe<Scalars['String']>;
};


export type PersonPartiesArgs = {
  filter?: Maybe<PartyPersonFilter>;
};


export type PersonInvitationsArgs = {
  filter?: Maybe<InvitationFilter>;
};

export type PersonChangeset = {
  id: Scalars['Int'];
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  side?: Maybe<SideEnum>;
  yearOfBirth?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export enum PersonColumns {
  Id = 'id',
  Firstname = 'firstname',
  Lastname = 'lastname',
  Gender = 'gender',
  Side = 'side',
  YearOfBirth = 'year_of_birth',
  AddressId = 'address_id',
  Address = 'address',
  UserId = 'user_id',
  ProfileImageUrl = 'profile_image_url'
}

export type PersonFilter = {
  and?: Maybe<Array<PersonFilter>>;
  or?: Maybe<Array<PersonFilter>>;
  not?: Maybe<PersonFilter>;
  id?: Maybe<Filter_Int_>;
  firstname?: Maybe<Filter_String_>;
  lastname?: Maybe<Filter_Nullable_String__>;
  gender?: Maybe<Filter_GenderEnum_>;
  side?: Maybe<Filter_SideEnum_>;
  year_of_birth?: Maybe<Filter_Nullable_Int__>;
  address_id?: Maybe<Filter_Nullable_Int__>;
  address?: Maybe<AddressFilter>;
  parties?: Maybe<Filter_Nullable_String__>;
  invitations?: Maybe<Filter_Nullable_String__>;
  user_id?: Maybe<PartyPersonFilter>;
  profile_image_url?: Maybe<InvitationFilter>;
};

export type PersonOrderBy = {
  column: PersonColumns;
  direction?: Maybe<Order>;
};

export type PersonsPrimaryKey = {
  id: Scalars['Int'];
};

export type Query = {
   __typename: 'Query';
  Persons: Array<Person>;
  Person?: Maybe<Person>;
  Events: Array<Event>;
  Event?: Maybe<Event>;
  Addresss: Array<Address>;
  Address?: Maybe<Address>;
  Partys: Array<Party>;
  Party?: Maybe<Party>;
  ResponseOptions: Array<ResponseOption>;
  ResponseOption?: Maybe<ResponseOption>;
  Invitations: Array<Invitation>;
  Invitation?: Maybe<Invitation>;
  Responses: Array<Response>;
  Response?: Maybe<Response>;
  Tables: Array<Table>;
  Table?: Maybe<Table>;
  NewsItems: Array<NewsItem>;
  NewsItem?: Maybe<NewsItem>;
  FaqItems: Array<FaqItem>;
  FaqItem?: Maybe<FaqItem>;
};


export type QueryPersonsArgs = {
  filter?: Maybe<PersonFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<PersonOrderBy>>;
};


export type QueryPersonArgs = {
  primaryKey: PersonsPrimaryKey;
};


export type QueryEventsArgs = {
  filter?: Maybe<EventFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<EventOrderBy>>;
};


export type QueryEventArgs = {
  primaryKey: EventsPrimaryKey;
};


export type QueryAddresssArgs = {
  filter?: Maybe<AddressFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AddressOrderBy>>;
};


export type QueryAddressArgs = {
  primaryKey: AddressesPrimaryKey;
};


export type QueryPartysArgs = {
  filter?: Maybe<PartyFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<PartyOrderBy>>;
};


export type QueryPartyArgs = {
  primaryKey: PartiesPrimaryKey;
};


export type QueryResponseOptionsArgs = {
  filter?: Maybe<ResponseOptionFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ResponseOptionOrderBy>>;
};


export type QueryResponseOptionArgs = {
  primaryKey: Response_OptionsPrimaryKey;
};


export type QueryInvitationsArgs = {
  filter?: Maybe<InvitationFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<InvitationOrderBy>>;
};


export type QueryInvitationArgs = {
  primaryKey: InvitationsPrimaryKey;
};


export type QueryResponsesArgs = {
  filter?: Maybe<ResponseFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ResponseOrderBy>>;
};


export type QueryResponseArgs = {
  primaryKey: ResponsesPrimaryKey;
};


export type QueryTablesArgs = {
  filter?: Maybe<TableFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<TableOrderBy>>;
};


export type QueryTableArgs = {
  primaryKey: TablesPrimaryKey;
};


export type QueryNewsItemsArgs = {
  filter?: Maybe<NewsItemFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<NewsItemOrderBy>>;
};


export type QueryNewsItemArgs = {
  primaryKey: News_ItemsPrimaryKey;
};


export type QueryFaqItemsArgs = {
  filter?: Maybe<FaqItemFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FaqItemOrderBy>>;
};


export type QueryFaqItemArgs = {
  primaryKey: Faq_ItemsPrimaryKey;
};

export type Rel_Events_Response_OptionsPrimaryKey = {
  id: Scalars['Int'];
};

export type Rel_Persons_PartiesPrimaryKey = {
  id: Scalars['Int'];
};

export type Rel_Tables_PersonsPrimaryKey = {
  id: Scalars['Int'];
};

export type Response = {
   __typename: 'Response';
  id: Scalars['Int'];
  response_time: Scalars['DateTimeUtc'];
  attending: Scalars['Boolean'];
  invalidated: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  response_user: Scalars['String'];
  invitation: Invitation;
  option_values: Array<ResponseOptionValue>;
};


export type ResponseOption_ValuesArgs = {
  filter?: Maybe<ResponseOptionValueFilter>;
};

export type Response_Option_ValuePrimaryKey = {
  id: Scalars['Int'];
};

export type Response_OptionsPrimaryKey = {
  id: Scalars['Int'];
};

export type ResponseChangeset = {
  id: Scalars['Int'];
  responseTime?: Maybe<Scalars['DateTimeUtc']>;
  attending?: Maybe<Scalars['Boolean']>;
  invalidated?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  responseUser?: Maybe<Scalars['String']>;
  invitationId?: Maybe<Scalars['Int']>;
};

export enum ResponseColumns {
  Id = 'id',
  ResponseTime = 'response_time',
  Attending = 'attending',
  Invalidated = 'invalidated',
  Message = 'message',
  ResponseUser = 'response_user',
  Invitation = 'invitation'
}

export type ResponseFilter = {
  and?: Maybe<Array<ResponseFilter>>;
  or?: Maybe<Array<ResponseFilter>>;
  not?: Maybe<ResponseFilter>;
  id?: Maybe<Filter_Int_>;
  response_time?: Maybe<Filter_DateTime_>;
  attending?: Maybe<Filter_Bool_>;
  invalidated?: Maybe<Filter_Bool_>;
  message?: Maybe<Filter_Nullable_String__>;
  response_user?: Maybe<Filter_String_>;
  invitation?: Maybe<InvitationFilter>;
  option_values?: Maybe<ResponseOptionValueFilter>;
};

export type ResponseOption = {
   __typename: 'ResponseOption';
  id: Scalars['Int'];
  label: Scalars['String'];
  values: Array<Scalars['String']>;
  kind: OptionKindEnum;
  events: Array<EventResponseOption>;
};


export type ResponseOptionEventsArgs = {
  filter?: Maybe<EventResponseOptionFilter>;
};

export type ResponseOptionChangeset = {
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
  kind?: Maybe<OptionKindEnum>;
};

export enum ResponseOptionColumns {
  Id = 'id',
  Label = 'label',
  Values = 'values',
  Kind = 'kind'
}

export type ResponseOptionFilter = {
  and?: Maybe<Array<ResponseOptionFilter>>;
  or?: Maybe<Array<ResponseOptionFilter>>;
  not?: Maybe<ResponseOptionFilter>;
  id?: Maybe<Filter_Int_>;
  label?: Maybe<Filter_String_>;
  values?: Maybe<Filter_Vec_String__>;
  kind?: Maybe<Filter_OptionKindEnum_>;
  events?: Maybe<EventResponseOptionFilter>;
};

export type ResponseOptionOrderBy = {
  column: ResponseOptionColumns;
  direction?: Maybe<Order>;
};

export type ResponseOptionValue = {
   __typename: 'ResponseOptionValue';
  id: Scalars['Int'];
  values: Array<Scalars['String']>;
  response: Response;
  response_option: ResponseOption;
};

export type ResponseOptionValueChangeset = {
  id: Scalars['Int'];
  values?: Maybe<Array<Scalars['String']>>;
  responseId?: Maybe<Scalars['Int']>;
  responseOptionId?: Maybe<Scalars['Int']>;
};

export type ResponseOptionValueFilter = {
  and?: Maybe<Array<ResponseOptionValueFilter>>;
  or?: Maybe<Array<ResponseOptionValueFilter>>;
  not?: Maybe<ResponseOptionValueFilter>;
  id?: Maybe<Filter_Int_>;
  values?: Maybe<Filter_Vec_String__>;
  response?: Maybe<ResponseFilter>;
  response_option?: Maybe<ResponseOptionFilter>;
};

export type ResponseOrderBy = {
  column: ResponseColumns;
  direction?: Maybe<Order>;
};

export type ResponsesPrimaryKey = {
  id: Scalars['Int'];
};

export enum SideEnum {
  Bride = 'BRIDE',
  Groom = 'GROOM',
  Both = 'BOTH'
}

export type Table = {
   __typename: 'Table';
  id: Scalars['Int'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  event: Event;
  persons: Array<TablePerson>;
};


export type TablePersonsArgs = {
  filter?: Maybe<TablePersonFilter>;
};

export type TableChangeset = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
};

export enum TableColumns {
  Id = 'id',
  Name = 'name',
  SortOrder = 'sort_order',
  Event = 'event'
}

export type TableFilter = {
  and?: Maybe<Array<TableFilter>>;
  or?: Maybe<Array<TableFilter>>;
  not?: Maybe<TableFilter>;
  id?: Maybe<Filter_Int_>;
  name?: Maybe<Filter_String_>;
  sort_order?: Maybe<Filter_Int_>;
  event?: Maybe<EventFilter>;
  persons?: Maybe<TablePersonFilter>;
};

export type TableOrderBy = {
  column: TableColumns;
  direction?: Maybe<Order>;
};

export type TablePerson = {
   __typename: 'TablePerson';
  id: Scalars['Int'];
  sort_order: Scalars['Int'];
  person: Person;
  table: Table;
};

export type TablePersonChangeset = {
  id: Scalars['Int'];
  personId?: Maybe<Scalars['Int']>;
  tableId?: Maybe<Scalars['Int']>;
};

export type TablePersonFilter = {
  and?: Maybe<Array<TablePersonFilter>>;
  or?: Maybe<Array<TablePersonFilter>>;
  not?: Maybe<TablePersonFilter>;
  id?: Maybe<Filter_Int_>;
  sort_order?: Maybe<Filter_Int_>;
  person?: Maybe<PersonFilter>;
  table?: Maybe<TableFilter>;
};

export type TablesPrimaryKey = {
  id: Scalars['Int'];
};

export type CreateFaqItemMutationVariables = {
  input: NewFaqItem;
};


export type CreateFaqItemMutation = (
  { __typename: 'Mutation' }
  & { CreateFaqItem?: Maybe<(
    { __typename: 'FaqItem' }
    & Pick<FaqItem, 'id'>
    & { author: (
      { __typename: 'Person' }
      & Pick<Person, 'id'>
    ) }
  )> }
);

export type CreateNewsItemMutationVariables = {
  input: NewNewsItem;
};


export type CreateNewsItemMutation = (
  { __typename: 'Mutation' }
  & { CreateNewsItem?: Maybe<(
    { __typename: 'NewsItem' }
    & Pick<NewsItem, 'id'>
    & { author: (
      { __typename: 'Person' }
      & Pick<Person, 'id'>
    ) }
  )> }
);

export type DeleteFaqItemMutationVariables = {
  input: Faq_ItemsPrimaryKey;
};


export type DeleteFaqItemMutation = (
  { __typename: 'Mutation' }
  & { DeleteFaqItem?: Maybe<(
    { __typename: 'DeletedCount' }
    & Pick<DeletedCount, 'count'>
  )> }
);

export type DeleteNewsItemMutationVariables = {
  input: News_ItemsPrimaryKey;
};


export type DeleteNewsItemMutation = (
  { __typename: 'Mutation' }
  & { DeleteNewsItem?: Maybe<(
    { __typename: 'DeletedCount' }
    & Pick<DeletedCount, 'count'>
  )> }
);

export type PostRsvpMutationVariables = {
  input: Array<NewResponse>;
};


export type PostRsvpMutation = (
  { __typename: 'Mutation' }
  & { CreateResponses: Array<(
    { __typename: 'Response' }
    & Pick<Response, 'id' | 'response_time' | 'attending' | 'invalidated' | 'message' | 'response_user'>
    & { invitation: (
      { __typename: 'Invitation' }
      & Pick<Invitation, 'id'>
    ), option_values: Array<(
      { __typename: 'ResponseOptionValue' }
      & Pick<ResponseOptionValue, 'id' | 'values'>
      & { response: (
        { __typename: 'Response' }
        & Pick<Response, 'id'>
      ), response_option: (
        { __typename: 'ResponseOption' }
        & Pick<ResponseOption, 'id'>
      ) }
    )> }
  )> }
);

export type PostRsvpOptionsMutationVariables = {
  input: Array<NewResponseOptionValue>;
};


export type PostRsvpOptionsMutation = (
  { __typename: 'Mutation' }
  & { CreateResponseOptionValues: Array<(
    { __typename: 'ResponseOptionValue' }
    & Pick<ResponseOptionValue, 'id'>
  )> }
);

export type UpdateFaqItemMutationVariables = {
  input: FaqItemChangeset;
};


export type UpdateFaqItemMutation = (
  { __typename: 'Mutation' }
  & { UpdateFaqItem?: Maybe<(
    { __typename: 'FaqItem' }
    & Pick<FaqItem, 'id'>
    & { author: (
      { __typename: 'Person' }
      & Pick<Person, 'id'>
    ) }
  )> }
);

export type UpdateRsvpMutationVariables = {
  input: ResponseChangeset;
};


export type UpdateRsvpMutation = (
  { __typename: 'Mutation' }
  & { UpdateResponse?: Maybe<(
    { __typename: 'Response' }
    & Pick<Response, 'id' | 'message'>
  )> }
);

export type EventsQueryVariables = {};


export type EventsQuery = (
  { __typename: 'Query' }
  & { Events: Array<(
    { __typename: 'Event' }
    & Pick<Event, 'id' | 'name' | 'start_time' | 'end_time' | 'description' | 'short_description' | 'event_type'>
  )> }
);

export type FaqItemQueryVariables = {
  input: Faq_ItemsPrimaryKey;
};


export type FaqItemQuery = (
  { __typename: 'Query' }
  & { FaqItem?: Maybe<(
    { __typename: 'FaqItem' }
    & Pick<FaqItem, 'id' | 'question_sv' | 'question_en' | 'answer_sv' | 'answer_en' | 'publish_timestamp'>
    & { author: (
      { __typename: 'Person' }
      & Pick<Person, 'firstname' | 'lastname'>
    ) }
  )> }
);

export type FaqItemsQueryVariables = {};


export type FaqItemsQuery = (
  { __typename: 'Query' }
  & { FaqItems: Array<(
    { __typename: 'FaqItem' }
    & Pick<FaqItem, 'id' | 'question_sv' | 'question_en' | 'answer_sv' | 'answer_en' | 'publish_timestamp'>
    & { author: (
      { __typename: 'Person' }
      & Pick<Person, 'firstname' | 'lastname'>
    ) }
  )> }
);

export type GuestsQueryVariables = {};


export type GuestsQuery = (
  { __typename: 'Query' }
  & { Persons: Array<(
    { __typename: 'Person' }
    & Pick<Person, 'id' | 'firstname' | 'lastname' | 'gender' | 'side' | 'year_of_birth' | 'address_id'>
    & { address: (
      { __typename: 'Address' }
      & Pick<Address, 'id' | 'address_line' | 'postal_code' | 'city' | 'country'>
    ) }
  )> }
);

export type MainEventsQueryVariables = {};


export type MainEventsQuery = (
  { __typename: 'Query' }
  & { Events: Array<(
    { __typename: 'Event' }
    & Pick<Event, 'id' | 'name' | 'start_time' | 'description' | 'short_description' | 'image_url' | 'location_url'>
    & { address: (
      { __typename: 'Address' }
      & Pick<Address, 'address_line' | 'postal_code' | 'city'>
    ) }
  )> }
);

export type NewsItemsQueryVariables = {};


export type NewsItemsQuery = (
  { __typename: 'Query' }
  & { NewsItems: Array<(
    { __typename: 'NewsItem' }
    & Pick<NewsItem, 'id' | 'title_sv' | 'title_en' | 'content_sv' | 'content_en' | 'publish_timestamp' | 'publish_start' | 'publish_end'>
    & { author: (
      { __typename: 'Person' }
      & Pick<Person, 'firstname' | 'lastname'>
    ) }
  )> }
);

export type PersonByNameQueryVariables = {
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
};


export type PersonByNameQuery = (
  { __typename: 'Query' }
  & { Persons: Array<(
    { __typename: 'Person' }
    & Pick<Person, 'id'>
  )> }
);

export type ResponsesQueryVariables = {};


export type ResponsesQuery = (
  { __typename: 'Query' }
  & { Responses: Array<(
    { __typename: 'Response' }
    & Pick<Response, 'id' | 'response_time' | 'attending' | 'invalidated'>
    & { invitation: (
      { __typename: 'Invitation' }
      & { event: (
        { __typename: 'Event' }
        & Pick<Event, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type RsvpFormQueryVariables = {
  id?: Maybe<Scalars['Int']>;
};


export type RsvpFormQuery = (
  { __typename: 'Query' }
  & { Persons: Array<(
    { __typename: 'Person' }
    & { parties: Array<(
      { __typename: 'PartyPerson' }
      & { party: (
        { __typename: 'Party' }
        & Pick<Party, 'id' | 'name'>
        & { people: Array<(
          { __typename: 'PartyPerson' }
          & { person: (
            { __typename: 'Person' }
            & Pick<Person, 'id' | 'firstname' | 'lastname'>
            & { invitations: Array<(
              { __typename: 'Invitation' }
              & Pick<Invitation, 'id' | 'rsvp'>
              & { event: (
                { __typename: 'Event' }
                & Pick<Event, 'id' | 'name' | 'description' | 'start_time' | 'event_type'>
                & { options: Array<(
                  { __typename: 'EventResponseOption' }
                  & Pick<EventResponseOption, 'id'>
                  & { response_option: (
                    { __typename: 'ResponseOption' }
                    & Pick<ResponseOption, 'id' | 'label' | 'values' | 'kind'>
                  ) }
                )> }
              ) }
            )> }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type TablesQueryVariables = {};


export type TablesQuery = (
  { __typename: 'Query' }
  & { Tables: Array<(
    { __typename: 'Table' }
    & Pick<Table, 'id' | 'name'>
    & { persons: Array<(
      { __typename: 'TablePerson' }
      & Pick<TablePerson, 'id'>
      & { person: (
        { __typename: 'Person' }
        & Pick<Person, 'firstname' | 'lastname' | 'gender'>
      ) }
    )> }
  )> }
);


export const CreateFaqItemDocument = gql`
    mutation CreateFaqItem($input: NewFaqItem!) {
  CreateFaqItem(NewFaqItem: $input) {
    id
    author {
      id
    }
  }
}
    `;
export type CreateFaqItemMutationFn = ApolloReactCommon.MutationFunction<CreateFaqItemMutation, CreateFaqItemMutationVariables>;
export type CreateFaqItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateFaqItemMutation, CreateFaqItemMutationVariables>, 'mutation'>;

    export const CreateFaqItemComponent = (props: CreateFaqItemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateFaqItemMutation, CreateFaqItemMutationVariables> mutation={CreateFaqItemDocument} {...props} />
    );
    
export type CreateFaqItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateFaqItemMutation, CreateFaqItemMutationVariables> & TChildProps;
export function withCreateFaqItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateFaqItemMutation,
  CreateFaqItemMutationVariables,
  CreateFaqItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateFaqItemMutation, CreateFaqItemMutationVariables, CreateFaqItemProps<TChildProps>>(CreateFaqItemDocument, {
      alias: 'createFaqItem',
      ...operationOptions
    });
};

/**
 * __useCreateFaqItemMutation__
 *
 * To run a mutation, you first call `useCreateFaqItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaqItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaqItemMutation, { data, loading, error }] = useCreateFaqItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFaqItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFaqItemMutation, CreateFaqItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFaqItemMutation, CreateFaqItemMutationVariables>(CreateFaqItemDocument, baseOptions);
      }
export type CreateFaqItemMutationHookResult = ReturnType<typeof useCreateFaqItemMutation>;
export type CreateFaqItemMutationResult = ApolloReactCommon.MutationResult<CreateFaqItemMutation>;
export type CreateFaqItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFaqItemMutation, CreateFaqItemMutationVariables>;
export const CreateNewsItemDocument = gql`
    mutation CreateNewsItem($input: NewNewsItem!) {
  CreateNewsItem(NewNewsItem: $input) {
    id
    author {
      id
    }
  }
}
    `;
export type CreateNewsItemMutationFn = ApolloReactCommon.MutationFunction<CreateNewsItemMutation, CreateNewsItemMutationVariables>;
export type CreateNewsItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewsItemMutation, CreateNewsItemMutationVariables>, 'mutation'>;

    export const CreateNewsItemComponent = (props: CreateNewsItemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewsItemMutation, CreateNewsItemMutationVariables> mutation={CreateNewsItemDocument} {...props} />
    );
    
export type CreateNewsItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateNewsItemMutation, CreateNewsItemMutationVariables> & TChildProps;
export function withCreateNewsItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNewsItemMutation,
  CreateNewsItemMutationVariables,
  CreateNewsItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNewsItemMutation, CreateNewsItemMutationVariables, CreateNewsItemProps<TChildProps>>(CreateNewsItemDocument, {
      alias: 'createNewsItem',
      ...operationOptions
    });
};

/**
 * __useCreateNewsItemMutation__
 *
 * To run a mutation, you first call `useCreateNewsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsItemMutation, { data, loading, error }] = useCreateNewsItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewsItemMutation, CreateNewsItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewsItemMutation, CreateNewsItemMutationVariables>(CreateNewsItemDocument, baseOptions);
      }
export type CreateNewsItemMutationHookResult = ReturnType<typeof useCreateNewsItemMutation>;
export type CreateNewsItemMutationResult = ApolloReactCommon.MutationResult<CreateNewsItemMutation>;
export type CreateNewsItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewsItemMutation, CreateNewsItemMutationVariables>;
export const DeleteFaqItemDocument = gql`
    mutation DeleteFaqItem($input: Faq_itemsPrimaryKey!) {
  DeleteFaqItem(DeleteFaqItem: $input) {
    count
  }
}
    `;
export type DeleteFaqItemMutationFn = ApolloReactCommon.MutationFunction<DeleteFaqItemMutation, DeleteFaqItemMutationVariables>;
export type DeleteFaqItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFaqItemMutation, DeleteFaqItemMutationVariables>, 'mutation'>;

    export const DeleteFaqItemComponent = (props: DeleteFaqItemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFaqItemMutation, DeleteFaqItemMutationVariables> mutation={DeleteFaqItemDocument} {...props} />
    );
    
export type DeleteFaqItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteFaqItemMutation, DeleteFaqItemMutationVariables> & TChildProps;
export function withDeleteFaqItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFaqItemMutation,
  DeleteFaqItemMutationVariables,
  DeleteFaqItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFaqItemMutation, DeleteFaqItemMutationVariables, DeleteFaqItemProps<TChildProps>>(DeleteFaqItemDocument, {
      alias: 'deleteFaqItem',
      ...operationOptions
    });
};

/**
 * __useDeleteFaqItemMutation__
 *
 * To run a mutation, you first call `useDeleteFaqItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqItemMutation, { data, loading, error }] = useDeleteFaqItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFaqItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFaqItemMutation, DeleteFaqItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFaqItemMutation, DeleteFaqItemMutationVariables>(DeleteFaqItemDocument, baseOptions);
      }
export type DeleteFaqItemMutationHookResult = ReturnType<typeof useDeleteFaqItemMutation>;
export type DeleteFaqItemMutationResult = ApolloReactCommon.MutationResult<DeleteFaqItemMutation>;
export type DeleteFaqItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFaqItemMutation, DeleteFaqItemMutationVariables>;
export const DeleteNewsItemDocument = gql`
    mutation DeleteNewsItem($input: News_itemsPrimaryKey!) {
  DeleteNewsItem(DeleteNewsItem: $input) {
    count
  }
}
    `;
export type DeleteNewsItemMutationFn = ApolloReactCommon.MutationFunction<DeleteNewsItemMutation, DeleteNewsItemMutationVariables>;
export type DeleteNewsItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteNewsItemMutation, DeleteNewsItemMutationVariables>, 'mutation'>;

    export const DeleteNewsItemComponent = (props: DeleteNewsItemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteNewsItemMutation, DeleteNewsItemMutationVariables> mutation={DeleteNewsItemDocument} {...props} />
    );
    
export type DeleteNewsItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteNewsItemMutation, DeleteNewsItemMutationVariables> & TChildProps;
export function withDeleteNewsItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteNewsItemMutation,
  DeleteNewsItemMutationVariables,
  DeleteNewsItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteNewsItemMutation, DeleteNewsItemMutationVariables, DeleteNewsItemProps<TChildProps>>(DeleteNewsItemDocument, {
      alias: 'deleteNewsItem',
      ...operationOptions
    });
};

/**
 * __useDeleteNewsItemMutation__
 *
 * To run a mutation, you first call `useDeleteNewsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsItemMutation, { data, loading, error }] = useDeleteNewsItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNewsItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNewsItemMutation, DeleteNewsItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNewsItemMutation, DeleteNewsItemMutationVariables>(DeleteNewsItemDocument, baseOptions);
      }
export type DeleteNewsItemMutationHookResult = ReturnType<typeof useDeleteNewsItemMutation>;
export type DeleteNewsItemMutationResult = ApolloReactCommon.MutationResult<DeleteNewsItemMutation>;
export type DeleteNewsItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNewsItemMutation, DeleteNewsItemMutationVariables>;
export const PostRsvpDocument = gql`
    mutation PostRsvp($input: [NewResponse!]!) {
  CreateResponses(NewResponses: $input) {
    __typename
    id
    response_time
    attending
    invalidated
    message
    response_user
    invitation {
      __typename
      id
    }
    option_values {
      __typename
      id
      values
      response {
        __typename
        id
      }
      response_option {
        __typename
        id
      }
    }
  }
}
    `;
export type PostRsvpMutationFn = ApolloReactCommon.MutationFunction<PostRsvpMutation, PostRsvpMutationVariables>;
export type PostRsvpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PostRsvpMutation, PostRsvpMutationVariables>, 'mutation'>;

    export const PostRsvpComponent = (props: PostRsvpComponentProps) => (
      <ApolloReactComponents.Mutation<PostRsvpMutation, PostRsvpMutationVariables> mutation={PostRsvpDocument} {...props} />
    );
    
export type PostRsvpProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PostRsvpMutation, PostRsvpMutationVariables> & TChildProps;
export function withPostRsvp<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PostRsvpMutation,
  PostRsvpMutationVariables,
  PostRsvpProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PostRsvpMutation, PostRsvpMutationVariables, PostRsvpProps<TChildProps>>(PostRsvpDocument, {
      alias: 'postRsvp',
      ...operationOptions
    });
};

/**
 * __usePostRsvpMutation__
 *
 * To run a mutation, you first call `usePostRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postRsvpMutation, { data, loading, error }] = usePostRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostRsvpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostRsvpMutation, PostRsvpMutationVariables>) {
        return ApolloReactHooks.useMutation<PostRsvpMutation, PostRsvpMutationVariables>(PostRsvpDocument, baseOptions);
      }
export type PostRsvpMutationHookResult = ReturnType<typeof usePostRsvpMutation>;
export type PostRsvpMutationResult = ApolloReactCommon.MutationResult<PostRsvpMutation>;
export type PostRsvpMutationOptions = ApolloReactCommon.BaseMutationOptions<PostRsvpMutation, PostRsvpMutationVariables>;
export const PostRsvpOptionsDocument = gql`
    mutation PostRsvpOptions($input: [NewResponseOptionValue!]!) {
  CreateResponseOptionValues(NewResponseOptionValues: $input) {
    id
  }
}
    `;
export type PostRsvpOptionsMutationFn = ApolloReactCommon.MutationFunction<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables>;
export type PostRsvpOptionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables>, 'mutation'>;

    export const PostRsvpOptionsComponent = (props: PostRsvpOptionsComponentProps) => (
      <ApolloReactComponents.Mutation<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables> mutation={PostRsvpOptionsDocument} {...props} />
    );
    
export type PostRsvpOptionsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables> & TChildProps;
export function withPostRsvpOptions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PostRsvpOptionsMutation,
  PostRsvpOptionsMutationVariables,
  PostRsvpOptionsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables, PostRsvpOptionsProps<TChildProps>>(PostRsvpOptionsDocument, {
      alias: 'postRsvpOptions',
      ...operationOptions
    });
};

/**
 * __usePostRsvpOptionsMutation__
 *
 * To run a mutation, you first call `usePostRsvpOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostRsvpOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postRsvpOptionsMutation, { data, loading, error }] = usePostRsvpOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostRsvpOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables>(PostRsvpOptionsDocument, baseOptions);
      }
export type PostRsvpOptionsMutationHookResult = ReturnType<typeof usePostRsvpOptionsMutation>;
export type PostRsvpOptionsMutationResult = ApolloReactCommon.MutationResult<PostRsvpOptionsMutation>;
export type PostRsvpOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<PostRsvpOptionsMutation, PostRsvpOptionsMutationVariables>;
export const UpdateFaqItemDocument = gql`
    mutation UpdateFaqItem($input: FaqItemChangeset!) {
  UpdateFaqItem(UpdateFaqItem: $input) {
    id
    author {
      id
    }
  }
}
    `;
export type UpdateFaqItemMutationFn = ApolloReactCommon.MutationFunction<UpdateFaqItemMutation, UpdateFaqItemMutationVariables>;
export type UpdateFaqItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateFaqItemMutation, UpdateFaqItemMutationVariables>, 'mutation'>;

    export const UpdateFaqItemComponent = (props: UpdateFaqItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateFaqItemMutation, UpdateFaqItemMutationVariables> mutation={UpdateFaqItemDocument} {...props} />
    );
    
export type UpdateFaqItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateFaqItemMutation, UpdateFaqItemMutationVariables> & TChildProps;
export function withUpdateFaqItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateFaqItemMutation,
  UpdateFaqItemMutationVariables,
  UpdateFaqItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateFaqItemMutation, UpdateFaqItemMutationVariables, UpdateFaqItemProps<TChildProps>>(UpdateFaqItemDocument, {
      alias: 'updateFaqItem',
      ...operationOptions
    });
};

/**
 * __useUpdateFaqItemMutation__
 *
 * To run a mutation, you first call `useUpdateFaqItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqItemMutation, { data, loading, error }] = useUpdateFaqItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFaqItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFaqItemMutation, UpdateFaqItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFaqItemMutation, UpdateFaqItemMutationVariables>(UpdateFaqItemDocument, baseOptions);
      }
export type UpdateFaqItemMutationHookResult = ReturnType<typeof useUpdateFaqItemMutation>;
export type UpdateFaqItemMutationResult = ApolloReactCommon.MutationResult<UpdateFaqItemMutation>;
export type UpdateFaqItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFaqItemMutation, UpdateFaqItemMutationVariables>;
export const UpdateRsvpDocument = gql`
    mutation UpdateRsvp($input: ResponseChangeset!) {
  UpdateResponse(UpdateResponse: $input) {
    id
    message
  }
}
    `;
export type UpdateRsvpMutationFn = ApolloReactCommon.MutationFunction<UpdateRsvpMutation, UpdateRsvpMutationVariables>;
export type UpdateRsvpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRsvpMutation, UpdateRsvpMutationVariables>, 'mutation'>;

    export const UpdateRsvpComponent = (props: UpdateRsvpComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRsvpMutation, UpdateRsvpMutationVariables> mutation={UpdateRsvpDocument} {...props} />
    );
    
export type UpdateRsvpProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateRsvpMutation, UpdateRsvpMutationVariables> & TChildProps;
export function withUpdateRsvp<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRsvpMutation,
  UpdateRsvpMutationVariables,
  UpdateRsvpProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRsvpMutation, UpdateRsvpMutationVariables, UpdateRsvpProps<TChildProps>>(UpdateRsvpDocument, {
      alias: 'updateRsvp',
      ...operationOptions
    });
};

/**
 * __useUpdateRsvpMutation__
 *
 * To run a mutation, you first call `useUpdateRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRsvpMutation, { data, loading, error }] = useUpdateRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRsvpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRsvpMutation, UpdateRsvpMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRsvpMutation, UpdateRsvpMutationVariables>(UpdateRsvpDocument, baseOptions);
      }
export type UpdateRsvpMutationHookResult = ReturnType<typeof useUpdateRsvpMutation>;
export type UpdateRsvpMutationResult = ApolloReactCommon.MutationResult<UpdateRsvpMutation>;
export type UpdateRsvpMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRsvpMutation, UpdateRsvpMutationVariables>;
export const EventsDocument = gql`
    query Events {
  Events(order: [{column: start_time, direction: ASC}]) {
    id
    name
    start_time
    end_time
    description
    short_description
    event_type
  }
}
    `;
export type EventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventsQuery, EventsQueryVariables>, 'query'>;

    export const EventsComponent = (props: EventsComponentProps) => (
      <ApolloReactComponents.Query<EventsQuery, EventsQueryVariables> query={EventsDocument} {...props} />
    );
    
export type EventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<EventsQuery, EventsQueryVariables> & TChildProps;
export function withEvents<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EventsQuery,
  EventsQueryVariables,
  EventsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EventsQuery, EventsQueryVariables, EventsProps<TChildProps>>(EventsDocument, {
      alias: 'events',
      ...operationOptions
    });
};

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
      }
export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const FaqItemDocument = gql`
    query FaqItem($input: Faq_itemsPrimaryKey!) {
  FaqItem(primaryKey: $input) {
    id
    question_sv
    question_en
    answer_sv
    answer_en
    publish_timestamp
    author {
      firstname
      lastname
    }
  }
}
    `;
export type FaqItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaqItemQuery, FaqItemQueryVariables>, 'query'> & ({ variables: FaqItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FaqItemComponent = (props: FaqItemComponentProps) => (
      <ApolloReactComponents.Query<FaqItemQuery, FaqItemQueryVariables> query={FaqItemDocument} {...props} />
    );
    
export type FaqItemProps<TChildProps = {}> = ApolloReactHoc.DataProps<FaqItemQuery, FaqItemQueryVariables> & TChildProps;
export function withFaqItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FaqItemQuery,
  FaqItemQueryVariables,
  FaqItemProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FaqItemQuery, FaqItemQueryVariables, FaqItemProps<TChildProps>>(FaqItemDocument, {
      alias: 'faqItem',
      ...operationOptions
    });
};

/**
 * __useFaqItemQuery__
 *
 * To run a query within a React component, call `useFaqItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqItemQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqItemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqItemQuery, FaqItemQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqItemQuery, FaqItemQueryVariables>(FaqItemDocument, baseOptions);
      }
export function useFaqItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqItemQuery, FaqItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqItemQuery, FaqItemQueryVariables>(FaqItemDocument, baseOptions);
        }
export type FaqItemQueryHookResult = ReturnType<typeof useFaqItemQuery>;
export type FaqItemLazyQueryHookResult = ReturnType<typeof useFaqItemLazyQuery>;
export type FaqItemQueryResult = ApolloReactCommon.QueryResult<FaqItemQuery, FaqItemQueryVariables>;
export const FaqItemsDocument = gql`
    query FaqItems {
  FaqItems(order: [{column: publish_timestamp, direction: DESC}]) {
    id
    question_sv
    question_en
    answer_sv
    answer_en
    publish_timestamp
    author {
      firstname
      lastname
    }
  }
}
    `;
export type FaqItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaqItemsQuery, FaqItemsQueryVariables>, 'query'>;

    export const FaqItemsComponent = (props: FaqItemsComponentProps) => (
      <ApolloReactComponents.Query<FaqItemsQuery, FaqItemsQueryVariables> query={FaqItemsDocument} {...props} />
    );
    
export type FaqItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<FaqItemsQuery, FaqItemsQueryVariables> & TChildProps;
export function withFaqItems<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FaqItemsQuery,
  FaqItemsQueryVariables,
  FaqItemsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FaqItemsQuery, FaqItemsQueryVariables, FaqItemsProps<TChildProps>>(FaqItemsDocument, {
      alias: 'faqItems',
      ...operationOptions
    });
};

/**
 * __useFaqItemsQuery__
 *
 * To run a query within a React component, call `useFaqItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFaqItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqItemsQuery, FaqItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqItemsQuery, FaqItemsQueryVariables>(FaqItemsDocument, baseOptions);
      }
export function useFaqItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqItemsQuery, FaqItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqItemsQuery, FaqItemsQueryVariables>(FaqItemsDocument, baseOptions);
        }
export type FaqItemsQueryHookResult = ReturnType<typeof useFaqItemsQuery>;
export type FaqItemsLazyQueryHookResult = ReturnType<typeof useFaqItemsLazyQuery>;
export type FaqItemsQueryResult = ApolloReactCommon.QueryResult<FaqItemsQuery, FaqItemsQueryVariables>;
export const GuestsDocument = gql`
    query Guests {
  Persons {
    id
    firstname
    lastname
    gender
    side
    year_of_birth
    address_id
    address {
      id
      address_line
      postal_code
      city
      country
    }
  }
}
    `;
export type GuestsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GuestsQuery, GuestsQueryVariables>, 'query'>;

    export const GuestsComponent = (props: GuestsComponentProps) => (
      <ApolloReactComponents.Query<GuestsQuery, GuestsQueryVariables> query={GuestsDocument} {...props} />
    );
    
export type GuestsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GuestsQuery, GuestsQueryVariables> & TChildProps;
export function withGuests<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GuestsQuery,
  GuestsQueryVariables,
  GuestsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GuestsQuery, GuestsQueryVariables, GuestsProps<TChildProps>>(GuestsDocument, {
      alias: 'guests',
      ...operationOptions
    });
};

/**
 * __useGuestsQuery__
 *
 * To run a query within a React component, call `useGuestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestsQuery, GuestsQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestsQuery, GuestsQueryVariables>(GuestsDocument, baseOptions);
      }
export function useGuestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestsQuery, GuestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestsQuery, GuestsQueryVariables>(GuestsDocument, baseOptions);
        }
export type GuestsQueryHookResult = ReturnType<typeof useGuestsQuery>;
export type GuestsLazyQueryHookResult = ReturnType<typeof useGuestsLazyQuery>;
export type GuestsQueryResult = ApolloReactCommon.QueryResult<GuestsQuery, GuestsQueryVariables>;
export const MainEventsDocument = gql`
    query MainEvents {
  Events(order: [{column: start_time, direction: ASC}], filter: {event_type: {eq: MAIN_EVENT}}) {
    id
    name
    start_time
    description
    short_description
    image_url
    location_url
    address {
      address_line
      postal_code
      city
    }
  }
}
    `;
export type MainEventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MainEventsQuery, MainEventsQueryVariables>, 'query'>;

    export const MainEventsComponent = (props: MainEventsComponentProps) => (
      <ApolloReactComponents.Query<MainEventsQuery, MainEventsQueryVariables> query={MainEventsDocument} {...props} />
    );
    
export type MainEventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<MainEventsQuery, MainEventsQueryVariables> & TChildProps;
export function withMainEvents<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MainEventsQuery,
  MainEventsQueryVariables,
  MainEventsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MainEventsQuery, MainEventsQueryVariables, MainEventsProps<TChildProps>>(MainEventsDocument, {
      alias: 'mainEvents',
      ...operationOptions
    });
};

/**
 * __useMainEventsQuery__
 *
 * To run a query within a React component, call `useMainEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MainEventsQuery, MainEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<MainEventsQuery, MainEventsQueryVariables>(MainEventsDocument, baseOptions);
      }
export function useMainEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MainEventsQuery, MainEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MainEventsQuery, MainEventsQueryVariables>(MainEventsDocument, baseOptions);
        }
export type MainEventsQueryHookResult = ReturnType<typeof useMainEventsQuery>;
export type MainEventsLazyQueryHookResult = ReturnType<typeof useMainEventsLazyQuery>;
export type MainEventsQueryResult = ApolloReactCommon.QueryResult<MainEventsQuery, MainEventsQueryVariables>;
export const NewsItemsDocument = gql`
    query NewsItems {
  NewsItems(order: [{column: publish_timestamp, direction: DESC}]) {
    __typename
    id
    title_sv
    title_en
    content_sv
    content_en
    publish_timestamp
    publish_start
    publish_end
    author {
      firstname
      lastname
    }
  }
}
    `;
export type NewsItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsItemsQuery, NewsItemsQueryVariables>, 'query'>;

    export const NewsItemsComponent = (props: NewsItemsComponentProps) => (
      <ApolloReactComponents.Query<NewsItemsQuery, NewsItemsQueryVariables> query={NewsItemsDocument} {...props} />
    );
    
export type NewsItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<NewsItemsQuery, NewsItemsQueryVariables> & TChildProps;
export function withNewsItems<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsItemsQuery,
  NewsItemsQueryVariables,
  NewsItemsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, NewsItemsQuery, NewsItemsQueryVariables, NewsItemsProps<TChildProps>>(NewsItemsDocument, {
      alias: 'newsItems',
      ...operationOptions
    });
};

/**
 * __useNewsItemsQuery__
 *
 * To run a query within a React component, call `useNewsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewsItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsItemsQuery, NewsItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsItemsQuery, NewsItemsQueryVariables>(NewsItemsDocument, baseOptions);
      }
export function useNewsItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsItemsQuery, NewsItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsItemsQuery, NewsItemsQueryVariables>(NewsItemsDocument, baseOptions);
        }
export type NewsItemsQueryHookResult = ReturnType<typeof useNewsItemsQuery>;
export type NewsItemsLazyQueryHookResult = ReturnType<typeof useNewsItemsLazyQuery>;
export type NewsItemsQueryResult = ApolloReactCommon.QueryResult<NewsItemsQuery, NewsItemsQueryVariables>;
export const PersonByNameDocument = gql`
    query PersonByName($firstname: String, $lastname: String) {
  Persons(filter: {firstname: {eq: $firstname}, lastname: {eq: $lastname}}) {
    id
  }
}
    `;
export type PersonByNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PersonByNameQuery, PersonByNameQueryVariables>, 'query'>;

    export const PersonByNameComponent = (props: PersonByNameComponentProps) => (
      <ApolloReactComponents.Query<PersonByNameQuery, PersonByNameQueryVariables> query={PersonByNameDocument} {...props} />
    );
    
export type PersonByNameProps<TChildProps = {}> = ApolloReactHoc.DataProps<PersonByNameQuery, PersonByNameQueryVariables> & TChildProps;
export function withPersonByName<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PersonByNameQuery,
  PersonByNameQueryVariables,
  PersonByNameProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PersonByNameQuery, PersonByNameQueryVariables, PersonByNameProps<TChildProps>>(PersonByNameDocument, {
      alias: 'personByName',
      ...operationOptions
    });
};

/**
 * __usePersonByNameQuery__
 *
 * To run a query within a React component, call `usePersonByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonByNameQuery({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *   },
 * });
 */
export function usePersonByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonByNameQuery, PersonByNameQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonByNameQuery, PersonByNameQueryVariables>(PersonByNameDocument, baseOptions);
      }
export function usePersonByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonByNameQuery, PersonByNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonByNameQuery, PersonByNameQueryVariables>(PersonByNameDocument, baseOptions);
        }
export type PersonByNameQueryHookResult = ReturnType<typeof usePersonByNameQuery>;
export type PersonByNameLazyQueryHookResult = ReturnType<typeof usePersonByNameLazyQuery>;
export type PersonByNameQueryResult = ApolloReactCommon.QueryResult<PersonByNameQuery, PersonByNameQueryVariables>;
export const ResponsesDocument = gql`
    query Responses {
  Responses {
    id
    response_time
    attending
    invalidated
    invitation {
      event {
        id
        name
      }
    }
  }
}
    `;
export type ResponsesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResponsesQuery, ResponsesQueryVariables>, 'query'>;

    export const ResponsesComponent = (props: ResponsesComponentProps) => (
      <ApolloReactComponents.Query<ResponsesQuery, ResponsesQueryVariables> query={ResponsesDocument} {...props} />
    );
    
export type ResponsesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResponsesQuery, ResponsesQueryVariables> & TChildProps;
export function withResponses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResponsesQuery,
  ResponsesQueryVariables,
  ResponsesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResponsesQuery, ResponsesQueryVariables, ResponsesProps<TChildProps>>(ResponsesDocument, {
      alias: 'responses',
      ...operationOptions
    });
};

/**
 * __useResponsesQuery__
 *
 * To run a query within a React component, call `useResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponsesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResponsesQuery, ResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<ResponsesQuery, ResponsesQueryVariables>(ResponsesDocument, baseOptions);
      }
export function useResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResponsesQuery, ResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResponsesQuery, ResponsesQueryVariables>(ResponsesDocument, baseOptions);
        }
export type ResponsesQueryHookResult = ReturnType<typeof useResponsesQuery>;
export type ResponsesLazyQueryHookResult = ReturnType<typeof useResponsesLazyQuery>;
export type ResponsesQueryResult = ApolloReactCommon.QueryResult<ResponsesQuery, ResponsesQueryVariables>;
export const RsvpFormDocument = gql`
    query RsvpForm($id: Int) {
  Persons(filter: {id: {eq: $id}}) {
    parties {
      party {
        id
        name
        people {
          person {
            id
            firstname
            lastname
            invitations {
              id
              rsvp
              event {
                id
                name
                description
                start_time
                event_type
                options {
                  id
                  response_option {
                    id
                    label
                    values
                    kind
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type RsvpFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RsvpFormQuery, RsvpFormQueryVariables>, 'query'>;

    export const RsvpFormComponent = (props: RsvpFormComponentProps) => (
      <ApolloReactComponents.Query<RsvpFormQuery, RsvpFormQueryVariables> query={RsvpFormDocument} {...props} />
    );
    
export type RsvpFormProps<TChildProps = {}> = ApolloReactHoc.DataProps<RsvpFormQuery, RsvpFormQueryVariables> & TChildProps;
export function withRsvpForm<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RsvpFormQuery,
  RsvpFormQueryVariables,
  RsvpFormProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RsvpFormQuery, RsvpFormQueryVariables, RsvpFormProps<TChildProps>>(RsvpFormDocument, {
      alias: 'rsvpForm',
      ...operationOptions
    });
};

/**
 * __useRsvpFormQuery__
 *
 * To run a query within a React component, call `useRsvpFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useRsvpFormQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRsvpFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRsvpFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RsvpFormQuery, RsvpFormQueryVariables>) {
        return ApolloReactHooks.useQuery<RsvpFormQuery, RsvpFormQueryVariables>(RsvpFormDocument, baseOptions);
      }
export function useRsvpFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RsvpFormQuery, RsvpFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RsvpFormQuery, RsvpFormQueryVariables>(RsvpFormDocument, baseOptions);
        }
export type RsvpFormQueryHookResult = ReturnType<typeof useRsvpFormQuery>;
export type RsvpFormLazyQueryHookResult = ReturnType<typeof useRsvpFormLazyQuery>;
export type RsvpFormQueryResult = ApolloReactCommon.QueryResult<RsvpFormQuery, RsvpFormQueryVariables>;
export const TablesDocument = gql`
    query Tables {
  Tables {
    id
    name
    persons {
      id
      person {
        firstname
        lastname
        gender
      }
    }
  }
}
    `;
export type TablesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TablesQuery, TablesQueryVariables>, 'query'>;

    export const TablesComponent = (props: TablesComponentProps) => (
      <ApolloReactComponents.Query<TablesQuery, TablesQueryVariables> query={TablesDocument} {...props} />
    );
    
export type TablesProps<TChildProps = {}> = ApolloReactHoc.DataProps<TablesQuery, TablesQueryVariables> & TChildProps;
export function withTables<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TablesQuery,
  TablesQueryVariables,
  TablesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TablesQuery, TablesQueryVariables, TablesProps<TChildProps>>(TablesDocument, {
      alias: 'tables',
      ...operationOptions
    });
};

/**
 * __useTablesQuery__
 *
 * To run a query within a React component, call `useTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTablesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TablesQuery, TablesQueryVariables>) {
        return ApolloReactHooks.useQuery<TablesQuery, TablesQueryVariables>(TablesDocument, baseOptions);
      }
export function useTablesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TablesQuery, TablesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TablesQuery, TablesQueryVariables>(TablesDocument, baseOptions);
        }
export type TablesQueryHookResult = ReturnType<typeof useTablesQuery>;
export type TablesLazyQueryHookResult = ReturnType<typeof useTablesLazyQuery>;
export type TablesQueryResult = ApolloReactCommon.QueryResult<TablesQuery, TablesQueryVariables>;