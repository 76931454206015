import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "../../lib/auth0";

const GroomsmenBridesmaidsSection = () => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuth0();
    return (
        <section className="groomsmen-bridesmaid section-padding bg-img-2 overlay-black" id="people">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title">
                            <h2 className="color-w">{t('common:header.menuPeople')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="groomsmen-bridesmaid-grids clearfix">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/groomsmen/img-1.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/connyjoh"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:bestman@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46704682832"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@conny:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Conny</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.bestMan')}</p>

                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/groomsmen/img-3.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/erik.lindberg.526"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:toastmaster@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46704861700"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@erik:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Erik</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.toastmaster')}</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/groomsmen/img-2.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/martin11254"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:groomsman.martin@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46706293128"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@martin:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Martin</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.groomsman')}</p>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/groomsmen/img-4.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="mailto:groomsman.dan@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46702978789"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@dan:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Dan</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.groomsman') + '/' + t('common:groomsmenBridesmaidsSection.witness')}</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/bridesmaid/img-3.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/kim.westerlund"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:maidofhonor@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46730640737"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@kim:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Kim</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.maidOfHonor')}</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/bridesmaid/img-2.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/malina.lindner"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:toastmadame@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+491714854388"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@malina:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Malina</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.toastmadame')}</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/bridesmaid/img-1.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/rosalie.nagy.1"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:maid.rose@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46766511223"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@rose:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Rose</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.bridesmaid')}</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src="/assets/images/bridesmaid/img-4.jpg" alt="" />
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/sandra.sande.3"><i className="ti-facebook"></i></a></li>
                                        <li><a href="mailto:witness.sandra@johanssons.wedding"><i className="ti-email"></i></a></li>
                                        {isAuthenticated &&
                                            <>
                                                <li><a href="tel:+46704244002"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@sandra:rduce.org"><i className="ti-comment"></i></a></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="details">
                                    <h4>Sandra</h4>
                                    <p>{t('common:groomsmenBridesmaidsSection.witness')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default GroomsmenBridesmaidsSection;
