import * as React from "react";
import { useState } from 'react';
import { useAuth0 } from '../lib/auth0'
import Loading from '../components/loading';
import saveAs from 'file-saver';
import { GridExporter } from "@devexpress/dx-react-grid-export";
import {
    GroupingState,
    IntegratedGrouping,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    SummaryState,
    IntegratedSummary,
    //    Column
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    ColumnChooser,
    TableColumnVisibility,
    TableSummaryRow,
    ExportPanel,
    TableFilterRow
} from '@devexpress/dx-react-grid-bootstrap3';
import {
    Person,
    //    Address 
} from '../graphql/types';
import { useGuestsQuery } from '../graphql/types';

  

const distinctCountCalculator = (type: any, rows: Person[], getValue: any) => {
    if (type === 'distinct') {
        return new Set(rows.map(g => g.id)).size;
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "Andre-And-Hannah-Guestlist.xlsx"
      );
    });
  };

const GuestList = (props: any) => {
    // State
    
    const { isAuthenticated } = useAuth0()
    const exporterRef = React.useRef<typeof GridExporter>(null);

    const startExport = React.useCallback(() => {
        exporterRef!.current!.exportGrid();
    }, [exporterRef]);

    // Grid setup
    const [defaultHiddenColumnNames] = useState(
        [
            'year_of_birth',
            'address_id',
            'id',
            'gender',
            'year_of_birth',
            'addres_id',
            'city',
            'country',
            'postal_code'
        ]);
    const [totalSummaryItems] = useState([
        { columnName: 'id', type: 'count' },
        { columnName: 'addressId', type: 'distinct' }
    ]);
    const [groupSummaryItems] = useState([
        { columnName: 'id', type: 'count', alignByColumn: true },
        { columnName: 'addressId', type: 'count', alignByColumn: true },
        { columnName: 'addressId', type: 'distinct', alignByColumn: true },
        { columnName: 'firstname', type: 'count', alignByColumn: true },
        { columnName: 'lastname', type: 'count', alignByColumn: true },
        { columnName: 'address', type: 'count', alignByColumn: true }
    ]);


    const [columns] = useState([
        {
            name: 'firstname',
            title: 'Firstname',
            getCellValue: (row: Person) => (row?.firstname),
        },
        {
            name: 'lastname',
            title: 'Lastname',
            getCellValue: (row: Person) => (row?.lastname),
        },
        {
            name: 'gender',
            title: 'Gender',
            getCellValue: (row: Person) => (row?.gender)?.toLowerCase(),
        },
        {
            name: 'id',
            title: 'Id',
            getCellValue: (row: Person) => (row?.id),
        },
        {
            name: 'side',
            title: 'Side',
            getCellValue: (row: Person) => (row?.side.toLowerCase()),
        },
        {
            name: 'year_of_birth',
            title: 'Year of birth',
            getCellValue: (row: Person) => (row?.year_of_birth),
        },
        {
            name: 'address_id',
            title: 'Address Id',
            getCellValue: (row: Person) => (row?.address?.id),
        },
        {
            name: 'address_line',
            title: 'Address',
            getCellValue: (row: Person) => (row?.address?.address_line),
        },
        {
            name: 'city',
            title: 'City',
            getCellValue: (row: Person) => (row?.address?.city),
        },
        {
            name: 'country',
            title: 'Country',
            getCellValue: (row: Person) => (row?.address?.country),
        },
        {
            name: 'postal_code',
            title: 'Postal Code',
            getCellValue: (row: Person) => (row?.address?.postal_code),
        },
    ]);

    // Data loading
    const { data, loading, error } = useGuestsQuery();
    const guests = data?.Persons;

    if (!isAuthenticated) {
        return <div>You are not allowed to see the guest list</div>;
    }

    if (loading || !guests) {
        return <Loading />;
    }

    if (error) {
        console.log('error', error);
        return <div>Could not retrieve guests</div>;
    }


    return <>
        <Grid
            rows={guests}
            columns={columns}
        >
            <DragDropProvider />

            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />

            <SortingState
                defaultSorting={[{ columnName: 'firstname', direction: 'asc' }]}
            />
            <IntegratedSorting />

            <GroupingState defaultGrouping={[]} />
            <IntegratedGrouping />
            <SummaryState
                totalItems={totalSummaryItems}
                groupItems={groupSummaryItems}
            />
            <IntegratedSummary
                calculator={distinctCountCalculator} />
            <Table />
            <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
            />
            <TableHeaderRow showSortingControls />
            <TableGroupRow showColumnsWhenGrouped />
            <TableFilterRow />
            <TableSummaryRow />
            <Toolbar />
            <ColumnChooser />
            <GroupingPanel />
            <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter        
            ref={exporterRef}
            columns={columns}
            rows={guests}
            onSave={onSave}
        /> 
    </>;
};

export default GuestList;