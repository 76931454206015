import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { useResponsesQuery, Event } from "../graphql/types";
import Loading from '../components/loading';
import Error from '../components/error';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

interface EventRsvpData {
    id: number,
    name: string,
    attendingCount: number,
    declinedCount: number
}

export const RsvpsPage = (props: any) => {
    const { data, loading, error } = useResponsesQuery();
    const { t } = useTranslation();

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error />
    }

    const allEvents = data?.Responses.map(r => r.invitation.event) ?? [];

    const distinctEvents: Partial<Event>[] = [];
    const map = new Map();
    for (const item of allEvents) {
        if (!map.has(item.id)) {
            map.set(item.id, true);
            distinctEvents.push(item as Partial<Event>);
        }
    }

    const responseData = distinctEvents.map(e => {
        return {
            id: e.id,
            name: t(e.name!),
            attendingCount: _.sumBy(data?.Responses, function(r) { return r.attending && !r.invalidated && r.invitation.event.id === e.id ? 1 : 0; }),
            declinedCount: _.sumBy(data?.Responses, function(r) { return !r.attending && !r.invalidated && r.invitation.event.id === e.id ? 1 : 0; }),
        } as EventRsvpData
    });

    return <section className="gift-registry-section section-padding-2">
        <div className="container">
            <div className="row">
                <div className="col col-lg-6 col-lg-offset-3">
                    <div className="section-title-s2">
                        <h2>Rsvps</h2>
                    </div>
                </div>
            </div>
            <div style={{
                margin: 'auto',
                width: 800
            }}>
                <BarChart
                    width={800}
                    height={600}
                    data={responseData}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="attendingCount" stackId="a" fill="#764b8e" />
                    <Bar dataKey="declinedCount" stackId="a" fill="#202020" />
                </BarChart>
            </div>
        </div>
    </section>
};
