import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "../lib/auth0";
import { 
    useFaqItemsQuery, 
    useCreateFaqItemMutation, 
    useUpdateFaqItemMutation, 
    useDeleteFaqItemMutation, 
    FaqItemChangeset, 
    NewFaqItem, 
    FaqItem, 
    usePersonByNameQuery 
} from "../graphql/types";
import moment from "moment";
import "moment/locale/sv";
import Loading from '../components/loading';
import Error from '../components/error';

interface FaqItemComponentProps {
    item: Partial<FaqItem>
}


export const FaqPage = (props: any) => {
    const { user, hasPermissions } = useAuth0();
    const { t } = useTranslation();
    const { data, loading, error, refetch } = useFaqItemsQuery();
    const { data: personData } = usePersonByNameQuery({
        variables: {
            firstname: user?.given_name ?? '',
            lastname: user?.family_name ?? ''
        }
    });

    const [createFaqItemMut] = useCreateFaqItemMutation();
    const [updateFaqItemMut] = useUpdateFaqItemMutation();    
    const [deleteFaqItemMut] = useDeleteFaqItemMutation();
    const [activeTab, setActiveTab] = useState('swedish');
    const [formState, setFormstate] = useState('');

    const [questionEn, setQuestionEn] = useState('');
    const [questionSv, setQuestionSv] = useState('');
    const [answerEn, setAnswerEn] = useState('');
    const [answerSv, setAnswerSv] = useState('');
    const [faqItemId, setFaqItemId] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);

    const authorId = personData && personData.Persons && personData.Persons.length ? personData?.Persons[0].id : 2;

    const createFaqItem = async () => {
        const item: NewFaqItem = {
            questionSv: questionSv,
            questionEn: questionEn,
            answerSv: answerSv,
            answerEn: answerEn,
            publishTimestamp: moment().toDate(),
            authorId: authorId
        };

        if (questionSv.length === 0
            || answerSv.length === 0
            || questionEn.length === 0
            || answerEn.length === 0) {
            setFormstate('warning');
            return;
        }

        await createFaqItemMut({
            variables: {
                input: item
            }
        }).then(async () => {
            setFormstate('success');
            await refetch();
        }).catch(() => {
            setFormstate('danger');
        });

    }
    
    const updateFaqItem = async () => {
        const item: FaqItemChangeset = {
            id: faqItemId,
            questionSv: questionSv,
            questionEn: questionEn,
            answerSv: answerSv,
            answerEn: answerEn,
            publishTimestamp: moment().toDate(),
            authorId: authorId
        };

        if (questionSv.length === 0
            || answerSv.length === 0
            || questionEn.length === 0
            || answerEn.length === 0
            || faqItemId === 0) {
            setFormstate('warning');
            return;
        }

        await updateFaqItemMut({
            variables: {
                input: item
            }
        }).then(async () => {
            setFormstate('success');
            setIsEditMode(false);
            await refetch();
        }).catch(() => {
            setFormstate('danger');
        });

    }

    const deleteFaqItem = async (id: number) => {
        await deleteFaqItemMut({
            variables: {
                input: {
                    id: id
                }
            }
        }).then(async () => {
            await refetch();
        }).catch((e) => {
            console.log(e)
        });
    }

    const setEditmode = (fi: Partial<FaqItem>) => {
        setFaqItemId(fi.id!);
        setQuestionSv(fi.question_sv!);
        setQuestionEn(fi.question_en!);
        setAnswerEn(fi.answer_en!);
        setAnswerSv(fi.answer_sv!);
        setIsEditMode(true); 
    }

    const FaqItemComponent = (props: FaqItemComponentProps) => {
        const { i18n } = useTranslation();
        const { item } = props;
        const question = i18n.language === 'sv' ? item.question_sv : item.question_en;
        const answer = i18n.language === 'sv' ? item.answer_sv : item.answer_en;

        return <div className="panel panel-default">
            <div className="panel-heading" style={{
                backgroundColor: '#582a72',
                color: 'white',
                padding: '10px'
            }}>
                <h3 className="panel-title" style={{
                    fontSize: '18px'
                }}>{question}</h3>
            </div>
            <div className="panel-body">
                <div
                    dangerouslySetInnerHTML={{
                        __html: answer!
                    }} >
                </div>

                {hasPermissions("is:admin") && (<>
                    <span className="glyphicon glyphicon-trash pull-right" onClick={() => deleteFaqItem(item.id!)} />
                    &nbsp;&nbsp;
                    <span className="glyphicon glyphicon-edit pull-right" onClick={() => setEditmode(item)} />
                    </>
                )}
            </div>
        </div>

    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error />
    }

    const FaqItemList = data!.FaqItems.map(i => <FaqItemComponent key={i.id} item={i} />);


    return <section className="gift-registry-section section-padding-2">
        <div className="container">
            <div className="row">
                <div className="col col-lg-6 col-lg-offset-3">
                    <div className="section-title-s2">
                        <h2>{t('common:faqPageTitle')}</h2>
                    </div>
                </div>
            </div>

            <div className="row form-group">
                <div className="col col-lg-8 col-lg-offset-2" dangerouslySetInnerHTML={{
                    __html: t('common:faqPageText')
                }} >
                </div>
            </div>

            {hasPermissions("is:admin") && (

                <div className="row form-group">
                    <div className="col col-lg-8 col-lg-offset-2">

                        <div className="panel panel-default">
                            <div className="panel-heading">{t('common:addFaqItem')}</div>
                            <div className="panel-body">
                                <ul className="nav nav-tabs">
                                    <li role="presentation" className={activeTab === 'swedish' ? 'active' : ''}><a onClick={() => setActiveTab('swedish')}>{t('common:swedish')}</a></li>
                                    <li role="presentation" className={activeTab === 'english' ? 'active' : ''}><a onClick={() => setActiveTab('english')}>{t('common:English')}</a></li>
                                </ul>
                                <form className="form-horizontal">
                                    {activeTab === 'swedish' &&
                                        <>
                                            <div className="form-group">
                                                <br />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputTitleSv" className="col-sm-2 control-label">{t('common:question')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" id="inputTitleSv" defaultValue={questionSv} onChange={e => setQuestionSv(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputContentSv" className="col-sm-2 control-label">{t('common:answer')}</label>
                                                <div className="col-sm-10">
                                                    <textarea className="form-control" rows={10} id="inputContentSv" defaultValue={answerSv} onChange={e => setAnswerSv(e.target.value)}></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <div className="alert alert-info" role="alert"><span className="glyphicon glyphicon-info-sign" />&nbsp;&nbsp;{t('common:addFaqItemPublishInfo')}</div>

                                                    {isEditMode &&
                                                        <button type="submit" className="btn btn-default" onClick={(e) => {
                                                            e.preventDefault();
                                                            updateFaqItem()
                                                        }}>{t('common:update')}</button>
                                                    }

                                                    {!isEditMode &&
                                                        <button type="submit" className="btn btn-default" onClick={(e) => {
                                                            e.preventDefault();
                                                            createFaqItem()
                                                        }}>{t('common:publish')}</button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {activeTab === 'english' &&
                                        <>
                                            <div className="form-group">
                                                <br />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputTitleEn" className="col-sm-2 control-label">{t('common:question')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" id="inputTitleEn" defaultValue={questionEn} onChange={e => setQuestionEn(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputContentEn" className="col-sm-2 control-label">{t('common:answer')}</label>
                                                <div className="col-sm-10">
                                                    <textarea className="form-control" rows={10} id="inputContentEn" defaultValue={answerEn} onChange={e => setAnswerEn(e.target.value)}></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <div className="alert alert-info" role="alert"><span className="glyphicon glyphicon-info-sign" />&nbsp;&nbsp;{t('common:addFaqItemPublishInfo')}</div>

                                                    {isEditMode &&
                                                        <button type="submit" className="btn btn-default" onClick={(e) => {
                                                            e.preventDefault();
                                                            updateFaqItem()
                                                        }}>{t('common:update')}</button>
                                                    }

                                                    {!isEditMode &&
                                                        <button type="submit" className="btn btn-default" onClick={(e) => {
                                                            e.preventDefault();
                                                            createFaqItem()
                                                        }}>{t('common:publish')}</button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {formState === 'success' &&
                                        <div className="alert alert-success" role="alert"><span className="glyphicon glyphicon-heart" />&nbsp;&nbsp;{t('common:addFaqItemSuccess')}</div>
                                    }

                                    {formState === 'warning' &&
                                        <div className="alert alert-warning" role="alert"><span className="glyphicon glyphicon-pencil" />&nbsp;&nbsp;{t('common:addFaqItemIncomplete')}</div>
                                    }

                                    {formState === 'danger' &&
                                        <div className="alert alert-danger" role="alert"><span className="glyphicon glyphicon-fire" />&nbsp;&nbsp;{t('common:addFaqItemFailure')}</div>
                                    }

                                </form>

                            </div>
                        </div>



                    </div>
                </div>
            )}

            <div className="row form-group">
                <div className="col col-lg-8 col-lg-offset-2">
                    <br />
                </div>
            </div>

            <div className="row form-group">
                <div className="col col-lg-8 col-lg-offset-2">
                    {FaqItemList}
                </div>
            </div>
        </div>
    </section>
};
