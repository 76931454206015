import React, { useState } from "react";
import { Person, usePostRsvpOptionsMutation, useUpdateRsvpMutation, NewResponseOptionValue } from "../../graphql/types";
import "moment/locale/sv";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";
import { useTranslation } from "react-i18next";
import PersonList from "./allergiesAndExtras/personList";
import RsvpHeader from "./rsvpHeader";

const RsvpAllergiesAndExtras = () => {
  // Hooks
  const { t } = useTranslation();
  const [addRsvpsOptions] = usePostRsvpOptionsMutation();
  const [updateRsvpsOptions] = useUpdateRsvpMutation();

  // Context
  const { state, dispatch } = useRsvpContext();

  // State
  const [message, setMessage] = useState('');
  const [formValues, setFormValues] = useState<NewResponseOptionValue[]>([]);

  const PostRsvp = () => {

    // Set message on responses
    if (message !== '' && state.savedResponses) {
      state.savedResponses.forEach(r => {
        updateRsvpsOptions({
          variables: {
            input: {
              id: r.id!,
              message: message
            }
          }
        }).catch(e => {
          console.log(e);
        });
      });
    }

    // post response option values
    addRsvpsOptions({
      variables: {
        input: formValues
      }
    }).then(r => {
      // dispatch next step
      console.log('rsvp options result', r, message)
      dispatch({
        savedResponseOptionValues: state.responseOptionValues,
        currentStep: RsvpStep.ThankYou
      });
    }).catch(e => {
      console.log(e);
    });

    // post to matrix channel in slack format
    const party = state.rsvpForm?.Persons[0].parties[0].party;
    var url = "https://webhooks.t2bot.io/api/v1/matrix/hook/m36Lf9LB4yzMhjlx3t1mpUP8meSwm8MGAIn1zXgoM9AY3VRqguaobLXXpfVsOxUZ";
    var payload = {
      "text": `${party?.name} just responded to the invitation! Check the latest stats on the webpage.`,
      "format": "plain",
      "displayName": "Bottie the wed bot",
      "avatarUrl": "https://johanssons.wedding/android-chrome-512x512.png"
    };

    fetch('https://cors-anywhere.herokuapp.com/' + url, {
      method: 'POST',
      cache: 'no-cache', 
      redirect: 'follow', 
      headers: {
        'origin': 'https://johanssons.wedding',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch(e => {
      console.log('could not call webhook');
    });

  }

  // Check form loaded
  if (!state.rsvpForm) {
    return <div>Could not retrieve rsvp form</div>;
  }

  // Get data
  const party = state.rsvpForm.Persons[0].parties[0].party;
  const people = party.people.map(pp => pp.person) as Person[];

  return (
    <>
      <RsvpHeader />
      <br />
      <h4>{t('common:rsvpOptionsTitle')}</h4>
      <br />
      <div style={{ textAlign: 'left' }} >
        <PersonList persons={people} onChange={newItem => {

          setFormValues(p => {
            const previous = p
              ? p.filter(r => !(r.responseId === newItem.responseId && r.responseOptionId === newItem.responseOptionId))
              : [];

            return [...previous, newItem];
          });
        }} />
      </div>
      <br />
      <div style={{ textAlign: 'center' }} >
        <h4>{t('common:rsvpMessageTitle')}</h4>
        <textarea style={{ width: '100%' }} placeholder={t('common:rsvpOptionsMessagePlaceholder')} onChange={e => setMessage(e.target.value)}>
        </textarea>
      </div>
      <br />
      <div className="submit-btn">
        <button className="theme-btn-s2" onClick={() => PostRsvp()}>{t('common:rsvpButtonPostRsvp')}</button>
      </div>
    </>
  );
};

export default RsvpAllergiesAndExtras;
