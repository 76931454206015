import React from 'react';

export const DashboardPage = (props: any) => {
    return <section className="gift-registry-section section-padding-2">
        <div className="container">
            <div className="row">
                <iframe
                    src="https://metabase-app.azurewebsites.net/public/dashboard/f44a0436-fa87-4a3d-8bd7-3b2d5d9f69e3"
                    frameBorder="0"
                    width="1920"
                    height="1080"
                    allowTransparency
                    title="Wedding dashboard"
                    style={{
                        margin: '0 auto'
                    }}
                ></iframe>

            </div>
        </div>
    </section>
};
