import React from "react";
import { useTranslation } from "react-i18next";
import { useMainEventsQuery } from "../../graphql/types";
import Loading from "../../components/loading";
import moment from "moment";
import 'moment-timezone';
import "moment/locale/sv";

const EventsSection = () => {
  const { t, i18n } = useTranslation();
  const { data, loading, error } = useMainEventsQuery();
  const events = data?.Events;
  moment.locale(i18n.language);

  if (loading || !events) {
    return <Loading />;
  }

  if (error) {
    console.log("error", error);
    return <div>Could not retrieve events</div>;
  }

  const EventItem = (props: any) => {
    const {
      id,
      image_url,
      start_time,
      name,
      location_url,
      address,
      short_description
    } = props;
    const { t } = useTranslation();
    const time = moment(start_time).tz("Europe/Stockholm");

    return (
      <div className="grid" key={id}>
        <div className="img-holder">
          <img src={image_url} alt="" />
        </div>
        <div className="date">
          <p
            dangerouslySetInnerHTML={{
              __html: `${time.format("D")} <span>${time.format("MMM")}</span>`
            }} >
          </p>
        </div>
        <div className="datails">
          <h3
            style={{
              color: "white"
            }}
          >
            {t(name)}
          </h3>
          <ul style={{
            paddingLeft: '0px'
          }}>
            <li>
              <i className="ti-time"></i>
              {time.format("ddd D MMM [, kl] HH")}
            </li>
            <li>
              <i className="ti-location-pin"></i>
              {address.address_line}
            </li>
          </ul>
          <p>{t(short_description)}</p>
          <a className="see-location-btn" href={location_url}>
            {t("common:event.seeLocation")}
            <i className="fa fa-angle-right"></i>
          </a>
        </div>
      </div>
    );
  };

  const EventsList = events.map((e: any) => <EventItem key={e.id} {...e} />);

  return (
    <section className="wedding-location-section section-padding-2" id="events">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="section-title">
              <h2>{t("common:header.menuEvents")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="location-grids clearfix">{EventsList}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventsSection;
