import React from "react";
import { useTranslation } from "react-i18next";

const CoupleSection = () => {
    const { t } = useTranslation();
    return (
        <section className="cupple-section section-padding-3 bg-1" id="couple">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="cupple-grids clearfix">
                            <div className="grid">
                                <div className="entry-meta">
                                    <div className="author">
                                        <img src="/assets/images/add/couple-2.jpg" alt="" />
                                    </div>
                                </div>

                                <div className="overlay">
                                    <div className="middle">
                                        <h3>{t('common:coupleSection.theGroom')}</h3>
                                        <p>{t('common:coupleSection.theGroomDescription')}</p>
                                        <img src="/assets/images/add/hannah-sign.png" alt="" />

                                        <div className="social">
                                            <ul>
                                                <li><a href="https://www.facebook.com/andre.johansson.165"><i className="ti-facebook"></i></a></li>
                                                <li><a href="mailto:groom@johanssons.wedding"><i className="ti-email"></i></a></li>
                                                <li><a href="tel:+46708376653"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@andre:rduce.org"><i className="ti-comment"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="entry-meta entry-meta-ma-top">
                                    <div className="author">
                                        <img src="/assets/images/add/couple.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="entry-meta">
                                    <div className="author">
                                        <img src="/assets/images/add/couple-1.jpg" alt="" />
                                    </div>
                                </div>

                                <div className="overlay">
                                    <div className="middle">
                                        <h3>{t('common:coupleSection.theBride')}</h3>
                                        <p>{t('common:coupleSection.theBrideDescription')}</p>
                                        <img src="/assets/images/add/andre-sign.png" alt="" />

                                        <div className="social">
                                            <ul>
                                                <li><a href="https://www.facebook.com/hannah.reisinger"><i className="ti-facebook"></i></a></li>
                                                <li><a href="mailto:bride@johanssons.wedding"><i className="ti-email"></i></a></li>
                                                <li><a href="tel:+46707209032"><i className="ti-headphone-alt"></i></a></li>
                                                <li><a href="https://matrix.to/#/@hannah:rduce.org"><i className="ti-comment"></i></a></li>
                                            </ul>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="left-shape"></div>
                <div className="right-shape"></div>
            </div>
        </section>
    );
}

export default CoupleSection;
