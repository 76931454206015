import React, {createContext, useContext, useReducer} from 'react';
import { RsvpFormQuery, Response, NewResponse, NewResponseOptionValue, ResponseOption } from "./graphql/types";

export enum RsvpStep {
    PickLogin = 0,
    EnterName = 1,
    Events = 2,
    Accommodations = 3,
    AllergiesAndExtras = 4,
    ThankYou = 5,
    Choose = 6,
    Sorry = 7
}

type ResponseOptionUpdateHandler = (
  response: Partial<Response>, 
  option: ResponseOption, 
  values: string[]
 ) => void


export interface RsvpForm {
    currentStep: RsvpStep,
    respondingPerson: number,

    rsvpForm?: RsvpFormQuery

    responses?: NewResponse[],
    savedResponses?: Partial<Response>[],

    responseOptionValues?: NewResponseOptionValue[],    
    savedResponseOptionValues?: NewResponseOptionValue[],    

    optionUpdateHandler?: ResponseOptionUpdateHandler
}

const initialState: RsvpForm = {
  currentStep: RsvpStep.PickLogin,
  respondingPerson: 0,
  rsvpForm: undefined,
  responses: undefined,
  responseOptionValues: undefined,
  savedResponseOptionValues: undefined,
  optionUpdateHandler: undefined
};


export const RsvpContext = createContext<{
    state: RsvpForm;
    dispatch: React.Dispatch<Partial<RsvpForm>>;
  }>({
        state: initialState,
        dispatch: () => null
  });

const reducer = (state:RsvpForm, newState: Partial<RsvpForm>) => {
    return {
        ...state,
        ...newState
    };
};

export const RsvpContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  return <RsvpContext.Provider value={{state, dispatch}}>
    {children}
  </RsvpContext.Provider>
};

export const useRsvpContext = () => useContext(RsvpContext);
