import browserUpdate from 'browser-update';

const config = {
    required: {
        i:999, // always warn on ie,
        f:-1,
        o:999, // always warn on opera
        s:-1,
        c:-1
    },

    text: "Your browser, {brow_name}, is too old or unsupported (use Firefox or Chrome): <a{up_but}>update</a> or <a{ignore_but}>ignore</a>.",
    // custom notification text (html)
    // Placeholders {brow_name} will be replaced with the browser name, {up_but} with contents of the update link tag and {ignore_but} with contents for the ignore link.
    // Example: "Your browser, {brow_name}, is too old: <a{up_but}>update</a> or <a{ignore_but}>ignore</a>."
    // more details (in english)

    text_in_sv: "Din webbläsare, {brow_name}, är utdaterad eller stöds ej (använd Firefox eller Chrome): <a{up_but}>uppdatera</a> eller <a{ignore_but}>ignorera</a>.",
    // custom notification text for language "xx"
    // e.g. text_de for german and text_it for italian

    url: "https://browsehappy.com/"   
};

browserUpdate(config);