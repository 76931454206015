import React from "react";
import { Invitation, NewResponseOptionValue } from "../../../graphql/types";
import EventResponseOptionComponent from "./eventResponseOption";

type InvitationEventOptionsListProps = {
    invitation: Invitation,
    onChange?: (newItem: NewResponseOptionValue) => void
}

type InvitationOptionValuesListProps = {
    invitations: Invitation[],
    onChange?: (newItem: NewResponseOptionValue) => void
}

const InvitationEventOptionsList = ({ invitation, onChange }: InvitationEventOptionsListProps) =>{
    const list = invitation.event.options.map((o) => 
        <EventResponseOptionComponent  key={'eventresponseoption-' + o.id  + '-' + invitation.id} eventResponseOption={o} invitation={invitation} onChange={onChange} />);
    return (<>{list}</>);    
}
    

const InvitationOptionValuesList = ({ invitations, onChange }: InvitationOptionValuesListProps) => {
    const list = invitations.map((i) => <InvitationEventOptionsList key={'invitation-' + i.id} invitation={i} onChange={onChange} />);
    return (<>{list}</>);
}    

export default InvitationOptionValuesList