import React from "react";
import { useTranslation } from "react-i18next";

export const MenuPage = (props: any) => {
  const { t } = useTranslation();
  return (
    <section className="gift-registry-section section-padding-2">
      <div className="container">
        <div className="row">
          <div className="col col-lg-6 col-lg-offset-3">
            <div className="section-title-s2">
              <h2>{t("common:menuPageTitle")}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-lg-8 col-lg-offset-2" dangerouslySetInnerHTML={{
            __html: t('common:menuPageText')
          }} >
          </div>
        </div>
      </div>
    </section>
  );
};
