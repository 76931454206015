// @ts-nocheck
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Network, DataSet, Node, Edge, Data, Options } from "vis-network";
import {
    useTablesQuery, GenderEnum
} from '../graphql/types';
import Loading from '../components/loading';

const options: Options = {}

export const SeatingPage = (props: any) => {
    const { t } = useTranslation();
    const { data, loading, error } = useTablesQuery();

    useEffect(() => {
        if (!data)
        {
            return;
        }

        const tableNodes = data?.Tables.map(t => {
            return { 
                id: "t" + t.id, 
                label: t.name,
                shape: "circularImage",
                image: "/assets/images/round-table.svg"
            }
        });


        const personNodes = [];
        const edges: DataSet<Edge> = [];

        data?.Tables.forEach(table => {
            table.persons.forEach(person => {
                const p = person.person;
                edges.push({
                    from: "p"+person.id, to: "t"+table.id
                });
                personNodes.push( { 
                    id: "p" + person.id, 
                    label: p.firstname,
                    shape: "circularImage",
                    image: p.gender === GenderEnum.Female ? "https://scontent-arn2-2.xx.fbcdn.net/v/t1.0-1/p240x240/67912774_10162071078225075_8450187192390647808_n.jpg?_nc_cat=108&_nc_sid=dbb9e7&_nc_ohc=H0PjaxJbXMkAX9oTJdz&_nc_ht=scontent-arn2-2.xx&_nc_tp=6&oh=168b4b0f045a816598a897c414f6dbaa&oe=5ED9BFFC" : "https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-1/p240x240/35687089_10156502281734275_5227086344395685888_o.jpg?_nc_cat=110&_nc_sid=dbb9e7&_nc_ohc=QxH4Ib-JDe4AX9fSHCQ&_nc_ht=scontent-arn2-1.xx&_nc_tp=6&oh=d0c3ae0b866065b7b9edfdc332c663e9&oe=5ED71E82"
                });
            });
        });

     

        const nodes: DataSet<Node> = tableNodes.concat(personNodes);
    
        const container: HTMLElement = document.getElementById("seatinggraph") as HTMLElement;
        const graphData: Data = {
            nodes: nodes,
            edges: edges
        };
        new Network(container, graphData, options);
    }, [data]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Could not retrieve tables</div>;
    }

    return <section className="gift-registry-section section-padding-2">
        <div className="container">
            <div className="row">
                <div className="col col-lg-6 col-lg-offset-3">
                    <div className="section-title-s2">
                        <h2>{t('common:header.menuAdminSeating')}</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div id="seatinggraph" style={{ height: '1000px', width: '100%' }}></div>
            </div>
        </div>
    </section>
};
