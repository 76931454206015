import React from "react";
import RsvpForm from "../rsvp/rsvpForm";

const RsvpSection = () => { 
  return (
    <section className="rsvp-section section-padding bg-1" id="rsvp">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col col-md-6 col-md-offset-6 col-sm-10">
            <div className="rspv-form">              
              <RsvpForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RsvpSection;
