import React from "react";
import { useTranslation } from "react-i18next";
import Countdown from 'react-countdown-now';
import Heart from "../../components/heart";

interface CountdownRenderer {
    total: number,
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number,
    completed: boolean
}

const WeddingDateSection = () => {
    const { t } = useTranslation();

    // Renderer callback with condition
    const renderer = (cd: CountdownRenderer) => {
        if (cd.completed) {
            // Render a completed state
            return <div className="row justify-content-center">
                <Heart
                    beat={1}
                />
            </div>;
        } else {
            // Render a countdown
            return <div className="col col-xs-12">
                <h3>{t('common:theDate')}</h3>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('common:saveTheDateText')
                    }} >
                </div>
                <div className="clock-grids">
                    <div id="clock">

                        <div className="box"><div><div className="time">{cd.days}</div> <span>{t('common:weddingDateSection.days')}</span> </div></div>
                        <div className="box"><div><div className="time">{cd.hours}</div> <span>{t('common:weddingDateSection.hours')}</span> </div></div>
                        <div className="box"><div><div className="time">{cd.minutes}</div> <span>{t('common:weddingDateSection.mins')}</span> </div></div>
                        <div className="box"><div><div className="time">{cd.seconds}</div> <span>{t('common:weddingDateSection.secs')}</span> </div></div>
                    </div>
                </div>
            </div>;
        }
    };

    return (
        <section className="wedding-date section-padding-2">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title">
                            <h2>{t('common:saveTheDate')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <Countdown
                        date={new Date('2020-09-11 15:00:00')}
                        renderer={renderer}
                    />


                </div>
            </div>
        </section>
    );
}
export default WeddingDateSection;
