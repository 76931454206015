import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "../../lib/auth0";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";
import { usePersonByNameQuery } from "../../graphql/types";

const RsvpPickLogin = () => {
  const { user, loginWithPopup } = useAuth0();
  const { dispatch } = useRsvpContext();
  const { t } = useTranslation();
  const { data, loading, error } = usePersonByNameQuery({
    variables: {
      firstname: user?.given_name ?? '',
      lastname: user?.family_name ?? ''
    }
  });

  // if we are logged in, then lookup person id by name and go to next step
  if (!loading && !error && data) {
    const id = data && data.Persons && data.Persons.length ? data?.Persons[0].id : 0;

    if (id > 0) {
      dispatch({ respondingPerson: id });
      dispatch({ currentStep: RsvpStep.Choose });
    }
  }

  return <>
    <h3>{t("common:rsvpSection.formTitle")}</h3>
              <br />
              <p>{t("common:rsvpSection.rsvpBefore")}</p>
    <div className="rsvp-form">
      <br />
      <br />
      <div className="submit-btn">
        <button className="theme-btn-s2" onClick={async () => await loginWithPopup()}>{t('common:loginUsingFacebook')}</button>
      </div>
      <br />
      <p>{t('common:or')}</p>
      <br />
      <div className="submit-btn">
        <button className="theme-btn-s2" onClick={() => dispatch({ currentStep: RsvpStep.EnterName })}>
          {t('common:enterNameButton')}
        </button>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: t('common:rsvpPickLoginInfo')
        }} >
      </div>
    </div>
  </>
};

export default RsvpPickLogin;