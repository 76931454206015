import React from "react";
import { useTranslation } from "react-i18next";

const StorySection = () => {
    const {t} = useTranslation();

    return (
    <section className="story-section section-padding" id="story">
        <div className="container">
            <div className="row">
                <div className="col col-xs-12">
                    <div className="section-title">
                        <div className="vertical-line"><span><i className="fi flaticon-two"></i></span></div>
                        <h2>{t('common:header.menuStory')}</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col col-lg-10 col-lg-offset-1">
                    <div className="story-timeline">
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="story-text right-align-text">
                                    <h3>{t('common:storySection.firstMeetTitle')}</h3>
                                    <span className="date">{t('common:storySection.firstMeetDate')}</span>
                                    <p>{t('common:storySection.firstMeetStory')}</p>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="img-holder">
                                    <img src="/assets/images/story/img-1.jpg" className="img img-responsive" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="img-holder right-align-text story-slider">
                                    <img src="/assets/images/story/img-2.jpg" className="img img-responsive" alt=""  />
                                </div>
                            </div>
                            <div className="col col-md-6 text-holder">
                                <span className="heart">
                                    <i className="fa ti-heart"></i>
                                </span>
                                <div className="story-text">
                                    <h3>{t('common:storySection.firstDateTitle')}</h3>
                                    <span className="date">{t('common:storySection.firstDateDate')}</span>
                                    <p>{t('common:storySection.firstDateStory')}</p>                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6 text-holder right-heart">
                                <span className="heart">
                                    <i className="fa ti-heart"></i>
                                </span>
                                <div className="story-text right-align-text">
                                    <h3>{t('common:storySection.moveTogetherTitle')}</h3>
                                    <span className="date">{t('common:storySection.moveTogetherDate')}</span>
                                    <p>{t('common:storySection.moveTogetherStory')}</p>                                               
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="img-holder right-align-text story-slider">
                                    <img src="/assets/images/story/img-3.jpg" className="img img-responsive" alt=""  />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="img-holder video-holder">
                                    <img src="/assets/images/story/img-4.jpg" className="img img-responsive" alt=""  />
                                </div>
                            </div>
                            <div className="col col-md-6 text-holder">
                                <span className="heart">
                                    <i className="fa ti-heart"></i>
                                </span>
                                <div className="story-text">
                                    <h3>{t('common:storySection.newHouseTitle')}</h3>
                                    <span className="date">{t('common:storySection.newHouseDate')}</span>
                                    <p>{t('common:storySection.newHouseStory')}</p>    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-md-6 text-holder right-heart">
                                <span className="heart">
                                    <i className="fa ti-heart"></i>
                                </span>
                                <div className="story-text right-align-text">
                                    <h3>{t('common:storySection.engagementTitle')}</h3>
                                    <span className="date">{t('common:storySection.engagementDate')}</span>
                                    <p>{t('common:storySection.engagementStory')}</p>                                        
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="img-holder right-align-text story-slider">
                                    <img src="/assets/images/story/img-6.png" className="img img-responsive" alt=""  />
                                </div>
                            </div>
                        </div>  
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="img-holder video-holder">
                                    <img src="/assets/images/story/img-7.jpg" className="img img-responsive" alt=""  />
                                </div>
                            </div>
                            <div className="col col-md-6 text-holder">
                                <span className="heart">
                                    <i className="fa ti-heart"></i>
                                </span>
                                <div className="story-text">
                                    <h3>{t('common:storySection.weddingTitle')}</h3>
                                    <span className="date">{t('common:storySection.weddingDate')}</span>
                                    <p>{t('common:storySection.weddingStory')}</p>    
                                </div>
                            </div>
                        </div>                 
                    </div>
                </div>
            </div>
        </div>
        <div className="story-shape"></div>
    </section>
);
}
export default StorySection;
