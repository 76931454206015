import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRsvpFormQuery, Person, Event, NewResponse, usePostRsvpMutation } from "../../graphql/types";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";
import Loading from "../../components/loading";
import "moment/locale/sv";
import RsvpHeader from "./rsvpHeader";

const RsvpChoose = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useRsvpContext();
  const [responses, setResponses] = useState<NewResponse[]>([]);
  const [addRsvps] = usePostRsvpMutation();
  const { data, loading, error } = useRsvpFormQuery({
    variables: {
      id: state.respondingPerson
    }
  });


  // Prepare responses
  useEffect(() => {
    const party = data?.Persons[0].parties[0].party;
    const people = party?.people.map(pp => pp.person) as Person[];

    if (people) {
      const responsesTmp: NewResponse[] = [];
      people.forEach(p => p.invitations.forEach(i => {
        responsesTmp.push(
          {
            attending: false,
            invalidated: false,
            invitationId: i.id,
            responseTime: new Date(),
            responseUser: state.respondingPerson.toString()
          });
      }));

      console.log('setting responses', responsesTmp);
      setResponses(responsesTmp);
    }

  }, [data, state.respondingPerson]);

  // Store rsvpform in context
  if (loading) {
    return <Loading />;
  }

  const party = data?.Persons[0].parties[0].party;
  const people = party?.people.map(pp => pp.person) as Person[];
  const events = people ? people
    .map(p => p.invitations.map(i => i.event))
    .reduce((a: Array<Event>, b: Array<Event>) => a.concat(b))
    : undefined;

  if (error || !data || !party || !events) {
    return <div>Could not retrieve rsvp form</div>;
  }

  const PostResponses = () => {
    // post responses
    addRsvps({
      variables: {
        input: responses
      }
    }).then(r => {
      dispatch({
        responses: responses,
        currentStep: RsvpStep.Sorry
      });
    }).catch(e => {
      console.log(e);
    });

    // post to matrix channel in slack format
    const party = data?.Persons[0].parties[0].party;
    var url = "https://webhooks.t2bot.io/api/v1/matrix/hook/m36Lf9LB4yzMhjlx3t1mpUP8meSwm8MGAIn1zXgoM9AY3VRqguaobLXXpfVsOxUZ";
    var payload = {
      "text": `${party?.name} just responded that they will not attend, :-(`,
      "format": "plain",
      "displayName": "Bottie the depressed wed bot",
      "avatarUrl": "https://johanssons.wedding/android-chrome-512x512.png"
    };

    fetch('https://cors-anywhere.herokuapp.com/' + url, {
      method: 'POST',
      cache: 'no-cache', 
      redirect: 'follow', 
      headers: {
        'origin': 'https://johanssons.wedding',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
    })
    .catch(e => {
      console.log('could not call webhook');
    });
  }

  return (
    <>
      <RsvpHeader />
      <br />
      <p>{t('common:rsvpChooseText')}</p>
      <br />
      <br />
      <div className="submit-btn">
       
        <br />

        <button className="theme-btn-s2" style={{
          backgroundColor: "#c20a00"
        }} onClick={() => PostResponses()}>{t('common:buttonCantCome')}</button>
      </div>
    </>
  );
};

export default RsvpChoose;
