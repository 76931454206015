import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "../lib/auth0";
import { useNewsItemsQuery, useCreateNewsItemMutation, useDeleteNewsItemMutation, NewNewsItem, NewsItem, usePersonByNameQuery } from "../graphql/types";
import moment from "moment";
import "moment/locale/sv";
import Loading from '../components/loading';
import Error from '../components/error';

interface NewsItemComponentProps {
    item: Partial<NewsItem>
}


export const NewsPage = (props: any) => {
    const { user, hasPermissions } = useAuth0();
    const { t } = useTranslation();
    const { data, loading, error, refetch } = useNewsItemsQuery();
    const { data: personData } = usePersonByNameQuery({
        variables: {
            firstname: user?.given_name ?? '',
            lastname: user?.family_name ?? ''
        }
    });

    const [
        createNewsItemMut
        //{ loading: mutationLoading, error: mutationError },
    ] = useCreateNewsItemMutation();

    const [
        deleteNewsItemMut
        //{ loading: mutationLoading, error: mutationError },
    ] = useDeleteNewsItemMutation();

    const [activeTab, setActiveTab] = useState('swedish');
    const [formState, setFormstate] = useState('');

    const [titleEn, setTitleEn] = useState('');
    const [titleSv, setTitleSv] = useState('');
    const [contentEn, setContentEn] = useState('');
    const [contentSv, setContentSv] = useState('');
    const authorId = personData && personData.Persons && personData.Persons.length ? personData?.Persons[0].id : 2;

    const createNewsItem = async () => {
        const item: NewNewsItem = {
            authorId: authorId, // Todo: extend backup and lookup person id from user login
            contentEn: contentEn,
            contentSv: contentSv,
            titleEn: titleEn,
            titleSv: titleSv,
            publishStart: moment().toDate(),
            publishTimestamp: moment().toDate(),
            publishEnd: null
        };

        if (titleSv.length === 0
            || contentSv.length === 0
            || titleEn.length === 0
            || contentEn.length === 0) {
            setFormstate('warning');
            return;
        }


        await createNewsItemMut({
            variables: {
                input: item
            }
        }).then(async () => {
            setFormstate('success');
            await refetch();
        }).catch(() => {
            setFormstate('danger');
        });

    }

    const deleteNewsItem = async (id: number) => {
        await deleteNewsItemMut({
            variables: {
                input: {
                    id: id
                }
            }
        }).then(async () => {
            await refetch();
        }).catch((e) => {
            console.log(e)
        });
    }

    const NewsItemComponent = (props: NewsItemComponentProps) => {
        const { i18n } = useTranslation();
        const { item } = props;
        const title = i18n.language === 'sv' ? item.title_sv : item.title_en;
        const content = i18n.language === 'sv' ? item.content_sv : item.content_en;
        const time = moment(item.publish_timestamp);

        return <div className="media">
            <div className="media-left">
                <div className="date" style={{
                    backgroundColor: '#582a72',
                    color: 'white',
                    width: '60px',
                    height: '60px',
                    textAlign: 'center',
                    padding: '5px'
                }}>
                    <strong><span>{time.format("D")}</span><br /><span>{time.format("MMM")}</span></strong>
                </div>
            </div>
            <div className="media-body">
                <div className="row">
                    <div className="col-md-6"><h3 className="media-heading">{title}</h3></div>
                    {hasPermissions("is:admin") && (
                        <div className="col-md-6"><span className="glyphicon glyphicon-trash pull-right" onClick={() => deleteNewsItem(item.id!)} /></div>
                    )}
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: content!
                    }} >
                </div>
                <h4 style={{
                    textAlign: 'right'
                }}>- {item.author!.firstname}</h4>

            </div>
        </div>
    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error />
    }

    const NewsList = data!.NewsItems.map(i => <NewsItemComponent key={i.id} item={i} />);


    return <section className="gift-registry-section section-padding-2">
        <div className="container">
            <div className="row">
                <div className="col col-lg-6 col-lg-offset-3">
                    <div className="section-title-s2">
                        <h2>{t('common:newsPageTitle')}</h2>
                    </div>
                </div>
            </div>

            <div className="row form-group">
                <div className="col col-lg-8 col-lg-offset-2" dangerouslySetInnerHTML={{
                    __html: t('common:newsPageText')
                }} >
                </div>
            </div>

            {hasPermissions("is:admin") && (

                <div className="row form-group">
                    <div className="col col-lg-8 col-lg-offset-2">

                        <div className="panel panel-default">
                            <div className="panel-heading">{t('common:addNewsItem')}</div>
                            <div className="panel-body">
                                <ul className="nav nav-tabs">
                                    <li role="presentation" className={activeTab === 'swedish' ? 'active' : ''}><a onClick={() => setActiveTab('swedish')}>{t('common:swedish')}</a></li>
                                    <li role="presentation" className={activeTab === 'english' ? 'active' : ''}><a onClick={() => setActiveTab('english')}>{t('common:English')}</a></li>
                                </ul>
                                <form className="form-horizontal">
                                    {activeTab === 'swedish' &&
                                        <>
                                            <div className="form-group">
                                                <br />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputTitleSv" className="col-sm-2 control-label">{t('common:title')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" id="inputTitleSv" defaultValue={titleSv} onChange={e => setTitleSv(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputContentSv" className="col-sm-2 control-label">{t('common:contentBody')}</label>
                                                <div className="col-sm-10">
                                                    <textarea className="form-control" rows={10} id="inputContentSv" defaultValue={contentSv} onChange={e => setContentSv(e.target.value)}></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <div className="alert alert-info" role="alert"><span className="glyphicon glyphicon-info-sign" />&nbsp;&nbsp;{t('common:addNewsItemPublishInfo')}</div>
                                                    <button type="submit" className="btn btn-default" onClick={(e) => {
                                                        e.preventDefault();
                                                        createNewsItem()
                                                    }}>{t('common:publish')}</button>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {activeTab === 'english' &&
                                        <>
                                            <div className="form-group">
                                                <br />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputTitleEn" className="col-sm-2 control-label">{t('common:title')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" id="inputTitleEn" defaultValue={titleEn} onChange={e => setTitleEn(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputContentEn" className="col-sm-2 control-label">{t('common:contentBody')}</label>
                                                <div className="col-sm-10">
                                                    <textarea className="form-control" rows={10} id="inputContentEn" defaultValue={contentEn} onChange={e => setContentEn(e.target.value)}></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <div className="alert alert-info" role="alert"><span className="glyphicon glyphicon-info-sign" />&nbsp;&nbsp;{t('common:addNewsItemPublishInfo')}</div>
                                                    <button type="submit" className="btn btn-default" onClick={(e) => {
                                                        e.preventDefault();
                                                        createNewsItem()
                                                    }}>{t('common:publish')}</button>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {formState === 'success' &&
                                        <div className="alert alert-success" role="alert"><span className="glyphicon glyphicon-heart" />&nbsp;&nbsp;{t('common:addNewsItemSuccess')}</div>
                                    }

                                    {formState === 'warning' &&
                                        <div className="alert alert-warning" role="alert"><span className="glyphicon glyphicon-pencil" />&nbsp;&nbsp;{t('common:addNewsItemIncomplete')}</div>
                                    }

                                    {formState === 'danger' &&
                                        <div className="alert alert-danger" role="alert"><span className="glyphicon glyphicon-fire" />&nbsp;&nbsp;{t('common:addNewsItemFailure')}</div>
                                    }

                                </form>

                            </div>
                        </div>



                    </div>
                </div>
            )}

            <div className="row form-group">
                <div className="col col-lg-8 col-lg-offset-2">
                    <br />
                </div>
            </div>

            <div className="row form-group">
                <div className="col col-lg-8 col-lg-offset-2">
                    {NewsList}
                </div>
            </div>
        </div>
    </section>
};
