import React from "react";
import { useRsvpContext, RsvpStep } from "../../rsvpContext";

import RsvpPickLogin from "./rsvpPickLogin";
import RsvpEnterName from "./rsvpEnterName";
import RsvpEvents from "./rsvpEvents";
import RsvpAccommodations from "./rsvpAccommodations";
import RsvpAllergiesAndExtras from "./rsvpAllergiesAndExtras";
import RsvpThankYou from "./rsvpThankYou";
import RsvpSorry from "./rsvpSorry";
import RsvpChoose from "./rsvpChoose";

const RsvpForm = () => {
  const { state } = useRsvpContext();

  const FormStep = () => {
    switch (state.currentStep) {
      case RsvpStep.PickLogin:
        return <RsvpPickLogin />;
      case RsvpStep.EnterName:
        return <RsvpEnterName />;
      case RsvpStep.Choose:
        return <RsvpChoose />;
        case RsvpStep.Events:
        return <RsvpEvents />;
      case RsvpStep.Accommodations:
        return <RsvpAccommodations />;
      case RsvpStep.AllergiesAndExtras:
        return <RsvpAllergiesAndExtras />;
      case RsvpStep.ThankYou:
        return <RsvpThankYou />;
      case RsvpStep.Sorry:
        return <RsvpSorry />;
      default:
        return null;
    }
  }

  return <FormStep />;
};

export default RsvpForm;
