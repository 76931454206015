import React from 'react';
import './App.css';
import { useAuth0 } from './lib/auth0'
import Loading from './components/loading';

import Hero from './components/hero';
import SaveTheDateSection from './pages/index/saveTheDate';
import CoupleSection from './pages/index/coupleSection';
import WeddingDateSection from './pages/index/weddingDateSection';
import StorySection from './pages/index/storySection';
import WishesSection from './pages/index/wishesSection';
import WeddingLocationSection from './pages/index/eventsSection';
import GroomsmenBridesmaidsSection from './pages/index/groomsmenBridesmaidsSection';
import GallerySection from './pages/index/gallerySection';
import RsvpSection from './pages/index/rsvpSection';
import GiftRegistrySection from './pages/index/giftRegistrySection';

const App = (props:any) => {
  const {isInitializing} = useAuth0()

  if (isInitializing) {
    return <Loading />
  }

  return (
      <>
      <Hero />
      <SaveTheDateSection />
      <CoupleSection />
      <WeddingDateSection />
      <RsvpSection />
      <StorySection />
      <WishesSection />
      <WeddingLocationSection />
      <GroomsmenBridesmaidsSection />
      <GallerySection />
      <GiftRegistrySection />
      </>
  );
};

export default App;
